import React from "react";
import { useLocation } from "react-router";
import Comments from "./eventDetails/Comments";
import Details from "./eventDetails/Details";
import Posts from "./eventDetails/Posts";
import Sales from "./eventDetails/Sales";
import { useState, useEffect } from "react";
import { uploadEventPicture } from "../actions/allEvents.actions";
import { getEventPosts } from "../actions/eventPosts.actions";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../utils/firebaseConfig";
import { getUsers } from "../actions/users.actions";
import { isEmpty } from "./Utils";
import axios from "axios";
import { HiLocationMarker } from "react-icons/hi";
import { MdDateRange, MdOutlineCategory } from "react-icons/md";
import { BsFillTagsFill } from "react-icons/bs";

const EventDetail = (props) => {
  const { eventDetail } = props;
  const eventId = eventDetail.id;
  const eventUid = eventDetail.uid;
  const userData = useSelector((state) => state.userReducer);
  const [popUpPosts, setPopUpPosts] = useState(false);
  const [popUpComments, setPopUpComments] = useState(false);
  const [popUpSales, setPopUpSales] = useState(false);
  const [popUpDetails, setPopUpDetails] = useState(true);
  const [file, setFile] = useState();
  const [uidToken, setUidToken] = useState();
  const [eventD, setEventD] = useState();
  const [loadPosts, setLoadPost] = useState(true);
  const dispatch = useDispatch();
  const [loginPopUp, setLoginPopUp] = useState(false);

  //let location = useLocation();
  //let eventDetail = location.state.eventData;

  const dict = [
    { value: "music", label: "Musique" },
    { value: "sport", label: "Evènement Sportif" },
    { value: "hobbies", label: "Loisir" },
    { value: "art", label: "Exposition et vernissage" },
    { value: "spectacles", label: "Théâtre et spectacle" },
    { value: "charity", label: "Evènement associatif" },
    { value: "conferences", label: "Conférences et Ateliers" },
  ];

  const [mobile, setMobile] = useState(true);

  const changeHome = () => {
    if (window.innerWidth <= 900) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  useEffect(() => {
    changeHome();
    window.addEventListener("resize", changeHome);
  }, []);

  const uploadEventPicture = (uidToken, data, eventId) => {
    axios
      .post(
        `${process.env.REACT_APP_AIRDEVE_URL}events/` + eventId + "/",
        data,
        {
          headers: {
            Authorization: `Bearer ${uidToken}`,
          },
        }
      )
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_AIRDEVE_URL}events/` + eventUid)
          .then((res) => {
            setEventD(res.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const updateEventPicture = (e) => {
    e.preventDefault();
    const data = new FormData();
    //console.log(file);
    data.append("coverImg", file);
   // console.log(eventId);
    if (file.size < 2e6) {
      uploadEventPicture(uidToken, data, eventId);
    } else {
      alert("image trop lourde (taille max 2Mo)");
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AIRDEVE_URL}events/` + eventUid)
      .then((res) => {
        setEventD(res.data);
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  //  }, [eventD]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            setUidToken(idToken);
            dispatch(getUsers(idToken));
            if (loadPosts) {
              dispatch(getEventPosts(eventId, idToken));

              setLoadPost(false);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setLoginPopUp(true);
      }
    });
  }, []);

  const uploadPosts = () => {
    setPopUpComments(false);
    setPopUpDetails(false);
    setPopUpSales(false);
    setPopUpPosts(true);
  };

  const uploadDetails = () => {
    setPopUpComments(false);
    setPopUpDetails(true);
    setPopUpSales(false);
    setPopUpPosts(false);
  };
  const uploadComments = () => {
    setPopUpComments(true);
    setPopUpDetails(false);
    setPopUpSales(false);
    setPopUpPosts(false);
  };
  const uploadSales = () => {
    setPopUpComments(false);
    setPopUpDetails(false);
    setPopUpPosts(false);
    setPopUpSales(true);
  };

  return (
    <div className="eventDetail">
      <div className="eventBanner">
        {mobile ? (
          <div className="line">
            <div className="banner">
              <div className="inLine">
                <h2> {eventDetail && eventDetail.name} </h2>
              </div>
              <br />
              <div className="inLine">
                <li>
                  <HiLocationMarker />
                  <h5> {eventDetail && eventDetail.location} </h5>
                </li>
              </div>

              <div className="inLine">
                <br />
                <li>
                  <MdDateRange />
                  <h5> {eventDetail && eventDetail.dateApproximation} </h5>
                </li>
              </div>
              <div className="inLine">
                <br />
                <li>
                  <BsFillTagsFill />
                  {eventDetail &&
                    dict.map((option) =>
                      eventDetail.category.name === option.value ? (
                        <h5> {option.label} </h5>
                      ) : null
                    )}
                </li>
              </div>
            </div>
            <div className="image">
              <img src={eventD && eventD.coverImgUrl} alt="EventPicture" />
            </div>
          </div>
        ) : (
          <div className="line">
            <div className="image">
              <img src={eventD && eventD.coverImgUrl} alt="EventPicture" />
            </div>
            <div className="banner">
              <div className="inLine">
                <li>
                  <h2> {eventDetail && eventDetail.name} </h2>
                </li>
                <br />
                <li>
                  <HiLocationMarker />
                  <h5> {eventDetail && eventDetail.location} </h5>
                </li>
              </div>

              <div className="inLine">
                <br />
                <li>
                  <MdDateRange />
                  <h5> {eventDetail && eventDetail.dateApproximation} </h5>
                </li>
              </div>
              <div className="inLine">
                <br />
                <li>
                  <BsFillTagsFill />
                  {eventDetail &&
                    dict.map((option) =>
                      eventDetail.category.name === option.value ? (
                        <h5> {option.label} </h5>
                      ) : null
                    )}
                </li>
              </div>
            </div>
          </div>
        )}
      </div>
      {loginPopUp ? (
        <div>
          <div className="windows">
            <ul>
              <li>
                <button onClick={uploadDetails} className="windowsBtn">
                  {" "}
                  Détails
                </button>
              </li>
              <li>
                <button onClick={uploadPosts} className="windowsBtn">
                  {" "}
                  Publications
                </button>
              </li>
              <li>
                <button onClick={uploadComments} className="windowsBtn">
                  {" "}
                  Commentaires
                </button>
              </li>
            </ul>
          </div>

          {popUpComments && (
            <div>
              <Comments event={eventDetail} />
            </div>
          )}

          {popUpDetails && (
            <div>
              <Details event={eventDetail} />
            </div>
          )}
          {popUpPosts && (
            <div>
              <Posts event={eventDetail} />
            </div>
          )}
        </div>
      ) : (
        <div>
          {eventD &&
            !isEmpty(eventD.organizer) &&
            userData.id === eventD.organizer.id && (
              <form
                action=""
                onSubmit={updateEventPicture}
                className="upload-pic"
              >
                <label htmlFor="file"> Modifier l'affiche</label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept=".jpg, .png, .jpeg"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <br />
                <button type="submit" value="Mettre à jour">
                  {" "}
                  Mettre à jour{" "}
                </button>
              </form>
            )}

          <div>
            <div className="windows">
              <ul>
                <li>
                  <button onClick={uploadDetails} className="windowsBtn">
                    {" "}
                    Détails
                  </button>
                </li>
                <li>
                  <button onClick={uploadPosts} className="windowsBtn">
                    {" "}
                    Publications
                  </button>
                </li>
                <li>
                  <button onClick={uploadComments} className="windowsBtn">
                    {" "}
                    Commentaires
                  </button>
                </li>
                {eventDetail &&
                  !isEmpty(eventDetail.organizer) &&
                  userData.id === eventDetail.organizer.id && (
                    <li>
                      <button onClick={uploadSales} className="windowsBtn">
                        {" "}
                        Ventes
                      </button>
                    </li>
                  )}
              </ul>
            </div>

            {popUpComments && (
              <div>
                <Comments event={eventDetail} />
              </div>
            )}

            {popUpDetails && (
              <div>
                <Details event={eventDetail} />
              </div>
            )}
            {popUpPosts && (
              <div>
                <Posts event={eventDetail} />
              </div>
            )}
            {popUpSales && (
              <div>
                <Sales event={eventDetail} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetail;
