import React, {useState} from 'react';
import {QrReader} from 'react-qr-reader';
import axios from 'axios';
import { dateParser } from '../components/Utils';
import { timestampParser } from '../components/Utils';


const CheckTicket = (props) => {

    const [barCode, setBarCode]= useState("No result");
    const [response, setResponse]= useState();
    const [responseDate, setResponseDate]= useState();

    const validateTicket= () => {

         axios
            .post(`${process.env.REACT_APP_AIRDEVE_URL}order-items/validate/`+ barCode)
            .then((res) => {
                //console.log(res.data);
                setResponse(res.data.status);
                setResponseDate(res.data.time);
                setBarCode("No result");
                

    })
            .catch((err)=> console.log(err));

}
    return (
        <div className='checkTicket'>
            <QrReader 
                onResult={(result, error) => {
                if (!!result) {
                  setBarCode(result?.text);
                  //setShowBtn(true);

                }
      
                if (!!error) {
                  console.log(error);
                }
              }}
              style={{ width: '100%' }}
              constraints={{
                facingMode: 'environment'
                 }}
            />
            
            {barCode === "No result" ? <p>En attente de QR Code</p> : 
            <button onClick={validateTicket}> Vérifier le ticket </button>}
            
             
           {
            response === "done" ? <div className='ticketValidated'> Billet validé </div> :
            (response === "already checked-in" ? <div className='wrongCode'> Billet déjà validé le {timestampParser(responseDate)}</div> : <div className='wrongCode'> QR Code non valide</div>)
           }

        </div>
    );
};

export default CheckTicket;