import axios from "axios";

export const GET_EVENT_POSTS = "GET_EVENT_POSTS";


export const getEventPosts = (eventId, idToken) => {


    return (dispatch) => {
        return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId +"/posts/" ,{
            headers: {
                Authorization: `Bearer ${idToken}`,
            }})
            .then ((res) => {
                dispatch({type: GET_EVENT_POSTS, payload: res.data})
            })
            .catch((err) => console.log(err));
};
};