import { GET_EVENT_TICKETS  } from "../actions/tickets.actions";

const initialState = {};

export default function ticketsReducer(state = initialState, action){

    switch (action.type){
        
        case GET_EVENT_TICKETS:
            return action.payload;
        
        default:
            return state;
    }
}