import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import Slider from "react-slick";



const About = () => {


    var settings1 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      
    const [mobile, setMobile] = useState(true);

    const changeView = () => {
        if (window.innerWidth <= 1200) {
          setMobile(false);
        } else {
          setMobile(true);
        }
      };

      useEffect(() => {
        changeView();
        window.addEventListener("resize", changeView);
        window.scrollTo(0, 0);
      }, []);

    return (

        <div className="about">
            <div className="banner">

            <div className="bannerText">
                    
                    <h1> AIRDEVE pour les créatifs </h1>
                    
                    <div className="text">
                        <h3>  Que vous souhaitiez interagir avec votre communauté, proposer du contenu original avec ou pour elle, ou même monétiser votre audience...</h3>
                        <h2> Les créatifs gagnent toujours en apportant de la valeur à la communauté.</h2>
                        <NavLink className="btn"exact to="EventCreation" > DEVENIR ORGANISATEUR</NavLink>
                    </div>
                   
                </div>
                <div className="aboutBanner">
                        <img src="./img/img_Site_web/AboutBanner.png" alt="Photo_1"/>
                </div>
               
                
            </div>

            <div className="quote2">
                <h1> Organiser avec Airdeve c’est : </h1>
            </div>
            <div class="whyline">
                    
                    <div class="image">
                    <h2> Ne pas perdre de l'argent </h2>
                        <h3> Une plateforme qui permet de débloquer le budget pour votre 
                            projet d’événement.Vous n’investissez pas votre argent. 
                            Comment ? Par notre propre billetterie de prévente. 
                            Et à tout moment, nous pouvons rembourser intégralement vos participants. </h3>
                    </div>
                    <div class="image">
                    <h2> Être acommpagné dans son projet </h2>
                        <h3> Organisation ? Logistique ? Administratif ? 
                            Nous nous en chargeons ! Notre équipe est à votre disposition 
                            pour votre événement. Faîtes ce qui vous passionne, 
                            présentez et communiquez avec votre communauté, 
                            on s’occupe du reste. </h3>
                    </div>
                    <div class="image">
                    <h2> Profiter de services adaptés </h2>
                        <h3> Une équipe pour vous accompagner dans la création 
                            de contenus de qualité pour la communication autour 
                            de votre campagne.</h3>
                    </div>
                    
            </div>

            <div className="eventList">
                        <img src="./img/img_Site_web/EventList.png" alt="Photo_1"/>
            </div>
           
        
            <div className="land2">
                <div className="line">
                    
                    <div className="text">
                        <h2> Pré-vendez des billets avant l’organisation de votre évènement pour valider l’intérêt de votre public. </h2>
                     
                        <NavLink className="btn"exact to="EventCreation" > Pré-vendre</NavLink>
                       
                    </div>
                    <div className="image">
                        <img src="./img/img_Site_web/sellTicket.png" alt="Photo_1"/>
                    </div>
                </div>
                
                  { mobile ? (
                

                <div className="line">
                    
                    <div className="image">
                        <img src="../img/img_Site_web/createEvent.png" alt="Photo_2"/>
                    </div>
                    <div className="text">
                        <h2> En présentant votre évènement sur Airdeve, vous créez vos tickets et vous définissez le montant dont vous avez besoin. </h2>
                     
                        <NavLink className="btn"exact to="EventCreation" > Testez votre idée</NavLink>
                       
                    </div>
                </div> ) : (

                <div className="line">
                    
                    <div className="text">
                        <h2> En présentant votre évènement sur Airdeve, vous créez vos tickets et vous définissez le montant dont vous avez besoin. </h2>
                        <NavLink className="btn"exact to="EventCreation" > Testez votre idée</NavLink>
                    </div>
                    <div className="image">
                        <img src="../img/img_Site_web/createEvent.png" alt="Photo_2"/>
                    </div>
                </div> )
                }

                <div className="line">
                    
                    <div className="text">
                        <h2> Lorsque suffisamment de tickets sont vendus, vous récupérez le montant des préventes et vous pouvez organiser votre évènement sans risque. Sinon tous les participants sont remboursés. </h2>
                        <NavLink className="btn"exact to="EventCreation" > Organisez avec zéro risque</NavLink>
                    </div>
                    <div className="image">
                        <img src="../img/img_Site_web/validateEvent.png" alt="Photo_3"/>
                    </div>
                </div>


                { mobile? (
                <div className="line">
                    
                    <div className="image">
                        <img src="../img/img_Site_web/createCommunity.png" alt="Photo_4"/>
                    </div>
                    <div className="text">
                        <h2> Vous créez une communauté autour de vos évènements en faisant des publications et en interagissant avec vos abonnés. Ces derniers sont notifiés à chacune de vos campagnes évènementielles.</h2>
                     
                        <NavLink className="btn"exact to="EventCreation" > Trouvez votre public</NavLink>
                    
                    </div>
                </div> ) : (

                <div className="line">
                    <div className="text">
                        <h2> Vous créez une communauté autour de vos évènements en faisant des publications et en interagissant avec vos abonnés. Ces derniers sont notifiés à chacune de vos campagnes évènementielles.</h2>
                        <NavLink className="btn"exact to="EventCreation" > Trouvez votre public</NavLink>
                    </div>
                    <div className="image">
                        <img src="./img/img_Site_web/createCommunity.png" alt="Photo_4"/>
                    </div>
                    
                </div>)
                }


            </div>
            <div className="quote2">
                <h1> COMMENT ÇA MARCHE ? </h1>
            </div>
            <div class="whyline">
                    
                    <div class="image">
                        <img src="./img/img_Site_web/PicSlide1.png" alt="Photo_1"/>
                        <h2> Sur Airdeve, chaque organisateur présente son événement
en précisant un nombre de participants à atteindre et le prix
de ses tickets. </h2>
                    </div>
                    <div class="image">
                        <img src="./img/img_Site_web/PicSlide2.png" alt="Photo_2"/>
                        <h2> Les "participants" peuvent acheter des tickets dans un délai
de trois mois (moins si l'organisateur a spécifié une date de
clotûre des ventes). </h2>
                    </div>
                    <div class="image">
                        <img src="./img/img_Site_web/PicSlide3.png" alt="Photo_3"/>
                        <h2> Si l'organisateur atteint ses objectifs, il perçoit la somme
collectée et organise son événement. Sinon les tickets sont
remboursés.</h2>
                    </div>
                    
                </div>

                {/*
                <div className="quote3">
                    <h1> IMAGINEZ-VOUS A LEUR PLACE! </h1>
                </div>

                <div className="line3">
                    
                    <div className="testimonial">
                        <h1>"</h1>
                        <p>
                            Avec la crise dans le secteur de l'évènementiel dû à la Covid, c'est un réel soulagement de pouvoir financer nos prochains évènements avec Airdeve. Je commence même à réfléchir à l'idée d'utiliser Airdeve pour financer l'organisation du mariage de nos rêves à moi et ma femme.
                        </p>
                        <hr/>
                        <h3> Thomas </h3>
                        <h4> Organisateur dans l'évènementiel </h4>

                    </div>
                    <div className="testimonial">
                        <h1>"</h1>
                        <p>
                        On propose à une influenceuse que je follow sur les réseaux d'organiser des meetups pour qu'on la rencontre mais elle craint que le concept qu'elle propose ne nous plaise pas et qu'il n'y ait pas de monde. Avec Airdeve, elle pourra désormais valider directement ses concepts avec nous. Trop cool je trouve.
                        </p>
                        <hr/>
                        <h3> Nathalie </h3>
                        <h4> ... </h4>

                    </div>
                    <div className="testimonial">
                        <h1>"</h1>
                        <p>
                        Nos soirées étudiantes ne seront clairement plus pareilles. On va organiser tous les événements qu'on voudra sans risquer qu'il n'y ait pas de participants. Vivement la reprise.
                        </p>
                        <hr/>
                        <h3> Eric </h3>
                        <h4> Etudiant </h4>
                    </div>
                    
                </div>
            */ }
                
                <div className="quote2">
                    <h1> LANCEZ-VOUS! </h1>

                </div>
                <div className="quote4">
                    <NavLink className="btn"exact to="EventCreation" > Créer votre évènement</NavLink>

                </div>

            <div className="quote2">
                <h1> PARTENAIRES </h1>
            </div>
            
          <div className="slider">
              <Slider {...settings1}>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner5.png" alt="Photo_1"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner1.png" alt="Photo_4"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner6.png" alt="Photo_2"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner2.png" alt="Photo_3"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner4.png" alt="Photo_2"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner3.png" alt="Photo_3"/>
                    </div>
              </Slider>
      </div>

        </div>
        
        
    )
}

export default About;