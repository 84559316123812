import React, { useEffect, useState } from "react";
import EventDetail from '../components/EventDetail';
import axios from "axios";
import { useParams } from "react-router-dom";
import NoEvent from "../components/NoEvent";

const Event = () => {

    const {eventId} = useParams();
    const [eventDetail, setEventDetail]= useState();
    const[eventFound, setEventFound] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    useEffect(()=> {
        setIsLoading(false);
        axios
        .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId )
        .then ((res) => {
              setEventDetail(res.data);
              //console.log(eventDetail);
              })
        .catch((err) => { setEventFound(false)});

            }, [])
    return (
        <div>
            
            {
                isLoading ? (
                    <i class="fas fa-spinner fa-pulse"></i>)
                :(
                    (eventFound) ?  (eventDetail &&
                        <EventDetail eventDetail = {eventDetail}/>)
                        : (<NoEvent/>)

    )
                

            }

        </div>
    );
};

export default Event;