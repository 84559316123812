import React, { useEffect, useState } from 'react';
import {dateParser } from './Utils';
import axios from 'axios';
import firebase from "../utils/firebaseConfig";
import { getUser } from '../actions/user.actions';
import { useDispatch,useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const OrderItemCard = (props) => {

    const {item} = props;
    const [orderItem, setOrderItem]= useState();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userReducer);
    const [event, setEvent] = useState();
    const [ticketType, setTicketType] = useState();


    useEffect(()=> {

        firebase.auth().onAuthStateChanged((user) => {
                if (user) { 
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            dispatch(getUser(idToken));
            axios
                  .get(`${process.env.REACT_APP_AIRDEVE_URL}orders/`+ item.orderId,{
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    }})
                  .then((res) => {
                    setOrderItem(res.data);
                    axios
                    .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ res.data.event.uid,{
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    }})
                    .then ((res) => {
                        setEvent(res.data);
                    })
                    .catch((err) => console.log(err)); 
                  })
                  .catch((err) => console.log(err));
             
            axios
                  .get (`${process.env.REACT_APP_AIRDEVE_URL}ticket-types/`+ item.ticketTypeId,{
                  headers: {
                      Authorization: `Bearer ${idToken}`,
                  }})
                  .then ((res) => {
                      setTicketType(res.data);
                  })
                  .catch((err) => console.log(err))
          })
          .catch(function (error) {
            console.log(error);
          });
    
        }
    
    })
      }, []);


    const createTicket = () => {
        const input = document.getElementById("App")
        html2canvas(input, {logging:true, letterRenderring:1, useCORS: true}).then(canvas=> {
            const imgWidth = 208;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            const imgData = canvas.toDataURL('img/png');
            const pdf = new jsPDF('p','mm','a4');
            pdf.addImage(imgData, 'PNG',5,5,imgWidth/2,imgHeight/2);
            pdf.save(userData.name + '_' + event.name +'_' + 'Ticket_Airdeve.pdf')
        })
        
       
    };

    return (

        <div className="orderItemCard"> 
        { orderItem && event && ticketType && ( 
        <div id= "App" className='contain' >
            <li className='qrCode'>
            <QRCode value= {item.barcode} />
            </li>
            <div className='trait'></div>
            <li>
            <span className="title"> Nom </span>
            </li>
            <li>
            <span className="username"> {userData.name}</span>
            </li>
            <li>
            <span className="title"> Evènement</span>
            </li>
            <li>
            <span className="text">{event.name}</span>
            </li>
            <li>
            <span className="title"> Date </span> 
            </li>
            <li>
            <span className="text">{ event.date == null ? event.dateApproximation: dateParser(event.date)}</span> 
            </li>
            <li>
            <span className="title">  Lieu</span>
            </li>
            <li>
            <span className="text">  {event.location}</span>
            </li>
            <li>
            <span className="title"> Numéro de commande</span>
            </li>
            <li>
            <span className="text">{orderItem.id}</span>
            </li>
            <li>
            <span className="title"> Numéro du ticket</span>
            </li>
            <li>
            <span className="text">{item.id}</span>
            </li>
            <div className='trait'></div>
            <li>
            <span className="title"> Informations </span>
            </li>
            <li>
            <span className="text">{ticketType.description}</span>
            </li>
            <li>
                <span className="title"> Prix</span>
            </li>
            <li>
                <span className="text"> {ticketType.cost === "0"? "Gratuit" : ticketType.cost.majorValue + "€"}</span>
          
            </li>
            <li>
            <span className="title"> Organisateur </span>
            </li>
            <li>
            <span className="text">{ event.organizer.username} </span>
            </li>
           
                    
                
        </div> )}
        <button onClick={createTicket}> Télécharger votre billet </button>
        </div>
    );
};

export default OrderItemCard;