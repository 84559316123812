import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
//import { UidContext } from "../Uidcontext";
import { uploadPicture } from '../../actions/user.actions';
import firebase from "../../utils/firebaseConfig";




    
const UploadPic = () => {

    const [file, setFile] = useState();
    const [uidToken, setUidToken]= useState();
    const dispatch = useDispatch();


    const updatePicture = (e) => {
        e.preventDefault();
        const data = new FormData();
        //console.log(file);
        data.append("profileImg", file);

        if (file.size < 2e6) {

        dispatch (uploadPicture(uidToken, data ))

        }
        else {
            alert("Image trop lourde (doit etre inférieure à 2Mo)")
        }
        //e.preventDefault();
        
    }
    useEffect(() => {
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
    
            setUidToken(idToken); 
            //console.log(idToken);
             }).catch(function(error) {
                console.log(error);
            });
    
        },[]);

    return (
        <form action='' onSubmit={updatePicture} className ="upload-pic">
            <label htmlFor='file'> Modifier votre photo</label>
            <input 
            type= "file" 
            id='file' 
            name='file' 
            accept= ".jpg, .png, .jpeg"
            onChange={(e) => setFile(e.target.files[0])}/>
            <br/>
            <button type="submit" value = "Mettre à jour"> Mettre à jour </button>
        </form>
        
        



            
        
    );
};

export default UploadPic; 