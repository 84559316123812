import React from 'react'
import { NavLink } from 'react-router-dom';


const NotFound = () => {
    return (
        <div className='notFound'>
            <br/>
            <br/>
            <br/>
            <h1> Erreur 404 </h1> 
           <br/>
           <h2> Oups! Vous vous êtes trompé de chemin. </h2> 
           <br/>
           <h3> La page que vous recherchez n'existe pas ! </h3>
           <br/>
           <div className= "btn">

           <NavLink exact to="/" activeClassName="nav-active">
                Page d'accueil
           </NavLink>

           </div>
           
           <br/>
           <br/>
           <br/>
    </div>
    )
}

export default NotFound;