import React, { useEffect, useState } from 'react';
import {dateParser } from './Utils';
import firebase from "../utils/firebaseConfig"
import { isEmpty } from './Utils';
import axios from 'axios';
import { NavLink } from 'react-router-dom';



const OrderCard = (props) => {
    const {orderItem} = props;
    const [ticketType, setTicketType] = useState();
    const [event, setEvent] = useState();


    useEffect(() => {
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
         
            
            !isEmpty(orderItem) && (
                
                axios
                .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ orderItem.event.uid,{
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }})
                .then ((res) => {
                    setEvent(res.data);
                })
                .catch((err) => console.log(err))) && (

                axios
                .get (`${process.env.REACT_APP_AIRDEVE_URL}ticket-types/`+ orderItem.items[0].ticketType.id,{
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }})
                .then ((res) => {
                    setTicketType(res.data);
                })
                .catch((err) => console.log(err)));
            
              
        });
    }, [orderItem])







    return (

    <div> 

        { ticketType && event && (
        <div className='orderCard'>
            <NavLink className="leftPart" exact to = { { pathname:"./orders/"+ orderItem.id, state: {orderItem: orderItem} }}>
            <img src={event.coverImgUrl} alt="Affiche"/>
            </NavLink>
            
            <div className="rightPart">
                <li>
                <span className="nameEvent"> Evènement: {event.name}</span>
                </li>
                <div className='dateLocation'>
                <span className="dateEvent"> Date: { event.date == null ? event.dateApproximation: dateParser(event.date)}</span>
                <span className="eventLocation">  {"  à " + event.location}</span>
                </div>
                <li>
                <span className="ticketDescription"> Ticket: {ticketType.description}</span>
                </li>
                <div className='cost'> 
                    <span> Prix: {ticketType.cost === "0"? "Gratuit" : ticketType.cost.majorValue + "€"}</span>
                </div>
                <li>
                <span className="ticketNumber"> Numéro de commande: {orderItem.id}</span>
                </li>
                <NavLink className="organizerLink" exact to = { { pathname:"./OrganizerProfil", state: {eventData: event} }}>
                    <div className='organizer'> 
                        <span className="eventOrganizer"> Organisé par: { event.organizer.username} </span>
                    </div>
                </NavLink>
                
            </div>
        </div>
        )}

    </div>
    );
};

export default OrderCard;