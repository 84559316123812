import React, { useEffect, useState } from 'react';
import { NavLink} from 'react-router-dom';
import firebase from "../utils/firebaseConfig";
import { getUser } from '../actions/user.actions';
import { getUsers } from '../actions/users.actions';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from './Utils';
import { BsLightbulbFill} from 'react-icons/bs';
import {CgProfile} from 'react-icons/cg';
import {IoIosCreate} from 'react-icons/io';
import {BiLogOut, BiLogIn} from 'react-icons/bi';
import Login from './Routes/Login';


const Navbar = () => {

  const userData = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [changeIcon, setChangeIcon] = useState(true);
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [user, setUser] = useState(false);

const disconnect= ()=> {
  firebase.auth().signOut();
  window.location.href = '/';
 

}
  
  const connect = () => {
    setLoginPopUp(true);
  }

  const changeNav = () => {
    if (window.innerWidth <= 1000) {
      setChangeIcon(false);
    } else {
      setChangeIcon(true);
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {

if (user) {
  firebase.auth().currentUser.getIdToken(true).then(function(idToken){
  
  
  dispatch(getUser(idToken)); 
  dispatch(getUsers(idToken)); 
}).catch(function(error) {
  console.log(error);
});
}
else {
  
   setUser(false);
}

      

    });

      changeNav();
      window.addEventListener('resize', changeNav);
    
  },[]);

    return (
    
    <nav>

      <div className= "nav-container">
        <div className = "logo">
            <NavLink exact to="/">
            <img src= "../img/new_logo.svg" alt= "logo"/>
            </NavLink>
        </div>
          <ul>
            <li></li>
            <li>
              {
                changeIcon? (
                  <NavLink exact to="/About" activeClassName="nav-active">
                À propos
              </NavLink>
                ): (
                  <NavLink exact to="/About" activeClassName="nav-activeIcon">
                <BsLightbulbFill className='about-icon'/>
              </NavLink>
                )
              }
              
            </li>
            {!isEmpty(userData) && (
            <li>
              {
                changeIcon? (
                  <NavLink exact to="/Profile" activeClassName="nav-active">
                {userData.username}
              </NavLink>
                ): (
                  <NavLink exact to="/Profile" activeClassName="nav-active">
                <CgProfile className='profile-icon'/>
              </NavLink>
                )
              }
            </li>
            )}
            <li>
              {
                changeIcon? (
                <NavLink exact to="/EventCreation" activeClassName="nav-active">
                Créer un évènement
              </NavLink>):(
                <NavLink exact to="/EventCreation" activeClassName="nav-active">
                <IoIosCreate className='event-creation-icon' />
              </NavLink>
              )
              }
              
            </li>
            { !isEmpty(userData) ? (
            <li className="btn">
              {changeIcon? (
                <div activeClassName= "btn-active" onClick={() => disconnect()}>Se déconnecter </div>
              ):(
                <div activeClassName= "btn-active" onClick={() => disconnect()}>
                  <BiLogOut className='logout-icon'/>
                </div>
              )

              }
              
            </li>) : 
            (
              
            <li className="btn">
              {changeIcon? (<div activeClassName= "btn-active" onClick={() => connect()}>Se connecter </div>): (
                <div activeClassName= "btn-active" onClick={() => connect()}>
                  <BiLogIn className='login-icon'/>
                </div>
              )
              }
              
              
            </li>
            
            ) 

            }
          </ul>
          {
            loginPopUp && (
              <Login/> )
          }

      </div>

  </nav>
    );
};

export default Navbar;