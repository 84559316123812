import React from 'react';
import { useEffect } from 'react';

const CookiesPolicies = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className='cgu-form'>
            <h1> POLITIQUE DES COOKIES </h1>
            <br/>
             <h5>
             Airdeve, éditeur du site https://www.airdeve.com/ et ses sous-domaines, traite vos données personnelles, en qualité de responsable de traitement tout au long de votre navigation sur le site.
              La présente politique a pour objet de vous informer aussi bien sur le fonctionnement des cookies que sur le cadre légal de leur utilisation par Airdeve. 
              Celle-ci est complémentaire à la Politique de protection des données personnelles.
            </h5> 

            <h2>Propriété du Site :</h2>
            <h5>
            Un cookie est un fichier texte déposé sur votre navigateur lors de la visite d'un site ou de la consultation d'une publicité. 
            Il a pour but de collecter des informations relatives à votre navigation afin de vous adresser des offres et services adaptés. 
            Il améliore votre navigation sur le site Internet et permet de s'assurer que les publicités et offres que vous voyez en ligne vous correspondent au mieux. 
            </h5>
            <h2>Cookies nécessaires </h2>
            <h5>
            Airdeve dépose différents cookies et procède à leur lecture par le traitement de vos données personnelles collectées. 
            Certains cookies impliquent un traitement de vos données personnelles au titre de l’intérêt légitime de Airdeve, afin de faciliter votre navigation. 
            Vous pouvez paramétrer les réglages de votre navigateur pour ne pas autoriser ce dépôt. D’autres cookies impliquent un traitement de données personnelles sous réserve de votre consentement dont la gestion est possible via un module disponible sur toutes les pages du site. 
            </h5>
            <br/>
            <h5>
            Airdeve utilise des cookies, dits techniques et fonctionnels, dans son intérêt légitime, 
            pour faciliter votre navigation entre les pages, vous fournir une expérience utilisateur optimale et adaptée à vos préférences personnelles (détection de problème de navigation, amélioration de l’ergonomie, etc.), 
            participer à la sécurité du site et enfin vous permettre de bénéficier de certaines fonctionnalités de notre site et/ou de nos services (création de compte, identification, sécurité, …). 
            Ils expirent automatiquement lorsque vous quittez le site. 
            </h5>
            <br/>
            <h5>
            Airdeve utilise en outre des cookies de mesure d’audience qui sont déposés sous réserve de votre consentement. 
            Ils nous permettent de collecter des informations nous permettant d’analyser le trafic sur notre site, 
            de compter le nombre de visiteurs sur le site, de comprendre comment vous arrivez sur notre site, 
            votre intérêt pour le contenu et mesurer la performance du site.
            </h5>
            <br/>
            <h5>
            Airdeve utilise aussi des cookies de personnalisation du contenu qui permettent d’adresser un contenu adapté à vos centres d'intérêts.
            </h5>
            <br/>
            <h5>
            Enfin, Airdeve utilise également des cookies tiers publicitaires, 
            sous réserve de votre consentement, afin d’adapter les offres publicitaires qu’elle vous soumet. 
            Ces cookies collectent des informations relatives à vos habitudes de recherches sur internet afin de permettre à Airdeve de vous proposer des produits adaptés à vos centres d’intérêt.
            </h5>
            <h2>Cookies de préférences </h2>

            <h5>
            L’utilisation des cookies avec Google Analytics nous permet de : 

            <h5>
            - La mesure des performances ;
            </h5>
            <h5>
            - la détection de problèmes de navigation ;
                
            </h5>
            <h5>
            - l’optimisation des performances techniques ;
                
            </h5>
            <h5>
            - l’optimisation de l’ergonomie du site ;
                
            </h5>
            <h5>
            - l’estimation de la puissance des serveurs nécessaires ;
                
            </h5>
            <h5>
            - l’analyse des contenus consultés.
                
            </h5>


            </h5>

            
        </div>
    );
};

export default CookiesPolicies;