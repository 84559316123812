import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import firebase from "../../utils/firebaseConfig";
import axios from 'axios';

const EditDeleteComment = ({comment}) => {
    const [isAuthor, setIsAuthor] = useState(false);
    const [edit, setEdit] = useState(false);
    const [text, setText]= useState("");
    const [uidToken, setUidToken] = useState('');
    const userData = useSelector((state) => state.userReducer);
    const commentId = comment.id;


    const handleEdit =(e) => {
        e.preventDefault();

        if (text) {
            const params = JSON.stringify({
            "payload": text,
        });
        axios
        .patch(`${process.env.REACT_APP_AIRDEVE_URL}events/comments/` + commentId  , params, {
        headers: {
            Authorization: `Bearer ${uidToken}`,
            'Content-Type' : 'application/json',
         }})
        .then ((res) => {
        setText('');
        setEdit(false);
         })
        .catch((err) => console.log(err));
        }

    };

    const handleDelete = () => {
        axios
        .delete(`${process.env.REACT_APP_AIRDEVE_URL}events/comments/` + commentId, {
        headers: {
            Authorization: `Bearer ${uidToken}`,
         }})
        .then ((res) => {
        console.log(res);
         })
        .catch((err) => console.log(err));

    };

    useEffect(()=> {

        const checkAuthor = ()=> {
            if (userData.id === comment.authorId) {
                setIsAuthor(true);
            }
        }
        checkAuthor();
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){

            setUidToken(idToken);
    
        }).catch(function(error) {
            console.log(error);
              
        });
    }, [userData, comment.authorId])



    return (
        <div className='edit-comment'>
            {isAuthor && edit === false && (
                <span onClick={()=> setEdit(!edit)}>
                    <img src= '../img/edit.svg' alt = "edit-comment" />
                </span>
            )}
            {isAuthor && edit && (
                <form action ="" onSubmit ={handleEdit} className = "edit-comment-form">
                    <br/>
                    <input type="text" name="text" onChange={(e) => setText(e.target.value)} defaultValue={comment.payload} />
                    <br/>
                    <div className='btn'>
                        <span onClick={()=> {
                            if (window.confirm("Voulez-vous supprimer le commentaire?")) {
                                handleDelete();
                            }
                        }}>
                            <img src='../img/delete.svg' alt='delete'/>
                        </span>
                        <input type="submit" value= "Valider" />
                        <label htmlFor='text' onClick={()=> setEdit(!edit)}>
                        Annuler
                        </label>


                    </div>
                </form>
            )

            }
            
        </div>
    );
};

export default EditDeleteComment;