import {
  GET_USER,
  UPLOAD_PICTURE,
  UPDATE_MYINFO,
  CHANGE_TO_ORGANIZER,
  CHANGE_TO_USER,
} from "../actions/user.actions";

const initialState = {};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return action.payload;
    case UPLOAD_PICTURE:
      return action.payload;
    case UPDATE_MYINFO:
      return action.payload;
    case CHANGE_TO_ORGANIZER:
      return {
        ...state,
        role: action.payload,
      };
    case CHANGE_TO_USER:
      return {
        ...state,
        role: action.payload,
      };

    default:
      return state;
  }
}
