import React from 'react';
import { useEffect } from 'react';

const PersonnalData = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className='cgu-form'>
            <h1> DONNÉES PERSONNELLES</h1>
            <h2> Utilisation de vos données par Airdeve </h2>
             <h5>
             Conformément aux lois relatives à l'Informatique, aux Fichiers et aux Libertés, 
             vous avez un droit permanent d'accès et de rectification sur toutes les données vous concernant, 
             conformément aux textes européens et aux lois nationales en vigueur. 
             Il vous suffit d'en faire la demande par courrier électronique ( contact@airdeve.com).
             
            </h5> 
            <br/>
            <h5>
            Lors du passage de votre commande, vous êtes amené à communiquer des informations personnelles sur votre identité ainsi que celles des Participants.
            </h5> 
            <br/>
            <h5>
            Les informations nécessaires afin d’assurer le traitement de la commande par la Billetterie sont : 
            nom, prénom et adresse e-mail de l’Acheteur. 
            Elles peuvent également être utilisées par Airdeve dans le but de vous contacter en cas de problème 
            avec le traitement de votre commande.
            </h5>
            <br/>
            <h5>
            D’autres informations peuvent être collectées à la demande de l’Organisateur, 
            notamment dans l’hypothèse d’un éventuel report ou annulation de l’Evènement. 
            La Société collecte ces données pour le compte de l’Organisateur et les porte à 
            sa connaissance. L’Organisateur est alors responsable de la bonne conservation et 
            utilisation des données auxquelles il a pu avoir accès. Notre système héberge les données 
            pour une durée de 1 à 3 ans suivant la fin de l’événement 
            (ce paramètre est défini par l’organisateur). 
            Au-delà de cette durée, les données sont anonymisées.
            </h5> 
            <br/>
            <h5>
            En acceptant les présentes Conditions Générales de Ventes, 
            vous autorisez expressément la Société à transmettre vos données personnelles 
            à l’Organisateur de l'Évènement. La Société ne pourrait être tenue responsable 
            de l’utilisation qui pourrait être faite de ces données. L’Organisateur est 
            le seul responsable de la gestion et du contenu qu’il adressera au Participant.
            </h5>
            <br/>
            <h5>
            Si vous créez ou utilisez un compte acheteur pour réaliser une commande, 
            vous autorisez Airdeve à collecter, conserver et retraiter vos données. 
            Lors d’une réservation ultérieure en utilisant ce compte, vous autorisez 
            Airdeve à transmettre une partie de vos données à l’organisateur pour lequel 
            vous réalisez cette commande. Ces données sont conservées pendant une durée 
            de 3 ans à compter du dernier achat réalisé par l'acheteur.
            </h5>
            <br/>
            <h2> Services Tiers</h2>
            
            <h5>
            Heroku: La plateforme Airdeve est hébergée sur Heroku une entreprise du groupe 
            Salesforce dont le siège est : salesforce.com, inc. Salesforce Tower 415 Mission Street, 
            3rd Floor San Francisco, California 94105. Nous y stockons vos données en toutes 
            sécurité lorsque vous vous inscrivez sur notre plateforme. Vous pouvez trouver 
            leur(s) lien(s) de politique de confidentialité à l’adresse suivante:
           <a href=" ​​https://www.salesforce.com/company/privacy/" target="_blank" rel="noopener noreferrer"> ​​https://www.salesforce.com/company/privacy/</a>
            </h5>
            <br/>
            <h5>
            Amazon AWS: Nous utilisons les services de Amazon AWS dont le siège social est :
              Amazon EU S.à r.l., 38 avenue John F. Kennedy, L-1855 Luxembourg, 
              Grand-Duché du Luxembourg pour stocker les images et vidéos que vous publiez
               sur la plateforme. Vous pouvez trouver leur(s) lien(s) de politique de 
               confidentialité à l’adresse suivante : 
           <a href="https://aws.amazon.com/fr/legal/?nc1=f_cc" target="_blank" rel="noopener noreferrer"> ​https://aws.amazon.com/fr/legal/?nc1=f_cc</a>
            </h5>
            <br/>
            <h5>
            Google Firebase: Firebase de Google nous permet de gérer en toute sécurité 
            l’authentification de nos utilisateurs. Son siège social est : Gordon House, 
            Barrow Street Dublin 4 Irlande. Vous pouvez trouver leur(s) lien(s) de 
            politique de confidentialité à l’adresse suivante: 
           <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer"> ​https://policies.google.com/privacy</a>
            </h5>
            <br/>
            <h5>
            Stripe: Stripe est le prestataire de paiement qui gère et sécurise les 
            transactions financières sur la plateforme. Son siège social est: 3180 18TH 
            STREET CA 94110 SAN FRANCISCO. Les données de paiement que vous fournissez 
            sont stockées par Stripe. Vous pouvez trouver leur(s) lien(s) de politique de 
            confidentialité à l’adresse suivante: 
           <a href="https://stripe.com/fr/privacy" target="_blank" rel="noopener noreferrer"> https://stripe.com/fr/privacy </a>
            </h5>

            
        </div>
    );
};

export default PersonnalData;