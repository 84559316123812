import React, { useState } from "react";
import Routes from "./components/Routes";
import { UidContext } from "./components/Uidcontext";
import CookieConsent from "react-cookie-consent";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  const [uid, setUid] = useState(null);

  return (
    <UidContext.Provider value={uid}>
      <div className="App" style={{ textAlign: "center" }}>
          <Routes />
          <CookieConsent
          buttonText="Fermer"
          style={{ background: "#eee", color: "#cc0066",textAlign: "left", fontSize:"14px"}}
          buttonStyle={{ color: "#eee", background: "#cc0066" }}
          expires={365}
          > Airdeve utilise des cookies 🍪 pour améliorer votre expérience utilisateur ! <a href="https://www.airdeve.com/CookiesPolicies"> Politique de cookies</a> </CookieConsent>
      </div>
    </UidContext.Provider>
  );
};

export default App;
