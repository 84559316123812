import axios from "axios";
export const GET_FOLLOWING = "GET_FOLLOWING";


export const getFollowing = (idToken, userId, followingCursor) => {


    return (dispatch) => {
        return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}users/`+ userId +"/following/",{
            headers: {
                Authorization: `Bearer ${idToken}`,
            }})
            .then ((res) => {
                dispatch({type: GET_FOLLOWING, payload: res.data})
            })
            .catch((err) => console.log(err));
};
};