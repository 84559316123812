import {
     GET_FOLLOWERS
    
  } from "../actions/followers.actions";
  
  const initialState = {};
  
  export default function followersReducer(state = initialState, action) {
    switch (action.type) {
      case GET_FOLLOWERS:
        return action.payload;
      default:
        return state;
    }
  }