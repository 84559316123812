import React, { useEffect, useState } from "react";
import firebase from "../utils/firebaseConfig";
import Myinfo from "../components/profileDetails/MyInfo";
import MyWallet from "../components/profileDetails/MyWallet";
import MyCreations from "../components/profileDetails/MyCreations";
import UploadPic from "../components/profileDetails/UploadPic";
import { getUser } from "../actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { getUsers} from "../actions/users.actions";
import { getFollowers } from "../actions/followers.actions";
import { getFollowing } from "../actions/following.actions";
import { isEmpty } from "./Utils";
import axios from "axios";

const ProfileDetails = ({userData}) => {


        const [popUpMyInfo, setPopUpMyInfo] = useState(false);
        const [popUpMyWallet, setPopUpMyWallet] = useState(false);
        const [popUpMyCreation, setPopUpMyCreation] = useState(true);
        const [followingStatus, setFollowingStatus] = useState(" ");
        const myData = useSelector((state) => state.userReducer);
        //const [followersCursor, setFollowersCursor] = useState(12);
        //const [followingCursor, setFollowingCursor] = useState(12);
        const dispatch = useDispatch();
        const [followers, setFollowers] = useState();
        const [following, setFollowing] = useState();
        const [followingPopUp, setFollowingPopUp] = useState(false);
        const [followersPopUp, setFollowersPopUp] = useState(false);
        const [followingCount, setFollowingCount] = useState("");
        const [uidToken, setUidToken] = useState('');
    
        const follow = async() => {
            await axios
            .post (`${process.env.REACT_APP_AIRDEVE_URL}users/follow/` + userData.id, null,
              {
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                setFollowingStatus(true);
                setFollowingCount(followingCount+1);
            })
            .catch((err) => console.log(err));
       };
       const unfollow = async() => {
        await axios
        .post (`${process.env.REACT_APP_AIRDEVE_URL}users/unfollow/` + userData.id, null,
          {
        headers: {
            Authorization: `Bearer ${uidToken}`,
        }})
        .then ((res) => {
            setFollowingStatus(false);
            setFollowingCount(followingCount-1);
        })
        .catch((err) => console.log(err));
   };
    
        const uploadMyInfo = () => {
    
    
            setPopUpMyInfo(true);
            setPopUpMyWallet(false);
            setPopUpMyCreation(false);
    
       };
        const uploadMyWallet = () => {
    
            
            setPopUpMyInfo(false);
            setPopUpMyWallet(true);
            setPopUpMyCreation(false);
    
        };
       const uploadMyCreations= () => {
    
           
            setPopUpMyInfo(false);
            setPopUpMyWallet(false);
            setPopUpMyCreation(true);
        };



        useEffect(() => {

            if (uidToken && userData) {
            axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}users/`+ userData.id +"/followers/" ,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                setFollowers(res.data);
            })
            .catch((err) => console.log(err));

            axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}users/`+ userData.id +"/following/" ,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                setFollowing(res.data)
            })
            .catch((err) => console.log(err));

            }


            
          },[uidToken, userData]);



        useEffect(() => {

            
            !isEmpty(userData) && setFollowingCount(userData._count.following);


            firebase.auth().onAuthStateChanged((user) => { 
                if (user) {
            firebase.auth().currentUser.getIdToken(true).then(function(idToken){
                setUidToken(idToken);
                axios
                .get (`${process.env.REACT_APP_AIRDEVE_URL}users/me/relationships?userId=`+ userData.id,{
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }})
                .then ((res) => {
                    setFollowingStatus(res.data[0].following);
                })
                .catch((err) => console.log(err));


                dispatch(getUser(idToken)); 
                dispatch(getUsers(idToken)); 
              }).catch(function(error) {
                console.log(error);
              }); 
        }
    });
            
          },[uidToken]);


    return (

        <div>
            {!isEmpty(userData) && (
           
        <div className = "profileDetail">
            
            <div className="profileBanner"> 
            <div className="profilePicture">
                <img src= {userData.profileImgUrl} alt= "user-pic" /> 
            </div>
            <br/>
            
            <div className="line"> 
            
           
            <br/>
            <h2> {userData.username} </h2>
            <h5> {userData.bio} </h5>
            <h3> "{userData.role}" </h3>

            <div className="followsCount">
                <h5 onClick={() => setFollowingPopUp(true)}> Abonnements : {userData._count? userData._count.following : " "} </h5>
                <h5 onClick={() => setFollowersPopUp(true)}> Abonnés : {userData._count? userData._count.followers : " "} </h5>
            </div>

            {userData.id !== myData.id && (            <div className="following-button">
                {followingStatus === true? (<button className="unfollow" onClick={unfollow} > abonné(e) </button>):
                (<button className="follow" onClick={follow} > s'abonner </button>)}
            
            </div>
            )}
            <br/>
            {userData.id === myData.id && (
            <UploadPic/>
            )}

            </div>
            </div>

            <div className="windows">
                <ul>
                {userData.id === myData.id && (

                <li>
                    <button onClick= {uploadMyInfo} className="windowsBtn" > Mes informations</button>
                </li>
                )}
                {userData.id === myData.id && (
                    <li>
                        <button onClick= {uploadMyWallet} className="windowsBtn" > Mes commandes</button>
                    </li>
                )}
                    <li>
                        <button onClick= {uploadMyCreations} className="windowsBtn"> Evènements créés</button>
                    </li>
                
                </ul>
            </div>
            {followersPopUp && (
                <div className="follow-poppup">
                    <div className="modal">
                        <h3> Abonnés</h3> 
                        <span className="cross" onClick={()=> setFollowersPopUp(false)}> &#10005; </span>
                        <ul>
                            {followers && followers.map((user) => {
                                return (
                                    <li key={user.id}>
                                        <img src={user.profileImgUrl} alt= "user-pic"/>
                                        <h4> {user.username}</h4>
                                    </li>
                                )

                            })
                            
                            }
                        </ul>
                    </div>
                
                </div>

            )}
            {followingPopUp && (
                <div className="follow-poppup">
                    <div className= "modal">
                        <h3> Abonnements</h3> 
                        <span className="cross" onClick={()=> setFollowingPopUp(false)}> &#10005; </span>
                        <ul>
                            {following && following.map((user) => {
                                return (
                                    <li key={user.id}>
                                        <img src={user.profileImgUrl} alt= "user-pic"/>
                                        <h4> {user.username}</h4>
                                    </li>
                                )

                            })
                            
                            }
                        </ul>
                    </div>
            
                </div>

            )}

            {popUpMyInfo && (
                <div>
                    <Myinfo userData={userData} />
                </div>
            )}

            {popUpMyCreation && (
                <div>
                    <MyCreations userData={userData} />
                </div>
            )}
            {popUpMyWallet && (
                <div>
                    <MyWallet userData={userData} />
                </div>
            )}

            

            
        </div>
            )}

        </div>
        
    );
};

export default ProfileDetails;