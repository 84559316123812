import axios from "axios";

export const GET_USER = "GET_USER";
export const UPLOAD_PICTURE = "UPLOAD_PICTURE";
export const UPDATE_MYINFO = "UPDATE_MYINFO";
export const CHANGE_TO_ORGANIZER = "CHANGE_TO_ORGANIZER";
export const CHANGE_TO_USER= "CHANGE_TO_USER";

export const getUser = (idToken) => {


    return (dispatch) => {
        return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}users/me/`,{
            headers: {
                Authorization: `Bearer ${idToken}`,
            }})
            .then ((res) => {
                dispatch({type: GET_USER, payload: res.data})
            })
            .catch((err) => console.log(err));
};
};

export const uploadPicture = (uidToken, data) => {
    return (dispatch) => {
        return axios 
        .post(`${process.env.REACT_APP_AIRDEVE_URL}users/me/`, data,  {
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }})
        .then ((res) => {
            return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}users/me/` ,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                dispatch({type: UPLOAD_PICTURE, payload: res.data})
                
            })
        })
        .catch((err) => console.log(err));
        
    };
};

export const updateMyInfo = (uidToken, data) => {
    return (dispatch) => {
        return axios 
        .post(`${process.env.REACT_APP_AIRDEVE_URL}users/me/`, data,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }})
        .then ((res) => {
            dispatch({type: UPDATE_MYINFO, payload: res.data});
            
                 
        })
        .catch((err) => console.log(err));
    };
};

export const changeToOrganizer = (uidToken) => {
    return (dispatch) => {
        return axios ({
            method: "patch",
            url: `${process.env.REACT_APP_AIRDEVE_URL}users/change/to/organizer`,
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }
    })
        .then ((res) => {
            dispatch({type: CHANGE_TO_ORGANIZER, payload: "ORGANIZER"})
            
                 
        })
        .catch((err) => console.log(err));
    };
};

export const changeToUser = (uidToken) => {
    return (dispatch) => {
        
        return axios ({
            method: "patch",
            url: `${process.env.REACT_APP_AIRDEVE_URL}users/change/to/user`,
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }
        })
        .then ((res) => {
            dispatch({type: CHANGE_TO_USER, payload: "USER"})   
            
        })
        .catch((err) => console.log(err));
    };
};