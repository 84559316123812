import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { timestampParser, isEmpty } from "../Utils";
import firebase from "../../utils/firebaseConfig";
import { useDispatch } from "react-redux";
import axios from "axios";
import LikeButton from "./LikeButton";
import { getUser } from "../../actions/user.actions";
import CardPostComment from "./CardPostComment";

const PostCard = ({ post }) => {
  const usersData = useSelector((state) => state.usersReducer);
  const userData = useSelector((state) => state.userReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [textUpdate, setTextUpdate] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [likes, setLikes] = useState([]);
  const [uidToken, setUidToken] = useState("");
  const [poster, setPoster] = useState();


  const updateCaption = async () => {
    setIsUpdated(false);
    const data = new FormData();
    data.append("caption", textUpdate);
    axios
      .post(`${process.env.REACT_APP_AIRDEVE_URL}posts/` + postId, data, {
        headers: {
          Authorization: `Bearer ${uidToken}`,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

    setIsUpdated(false);
  };
  const deletePost = async () => {
    axios
      .delete(`${process.env.REACT_APP_AIRDEVE_URL}posts/` + postId, {
        headers: {
          Authorization: `Bearer ${uidToken}`,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const postId = post.id;
  const dispatch = useDispatch();

  useEffect(() => {
    !isEmpty(usersData[0]) && setIsLoading(false);
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        dispatch(getUser(idToken));
        setUidToken(idToken);
        axios
          .get(`${process.env.REACT_APP_AIRDEVE_URL}posts/` + postId + "/comments/", {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((res) => {
            setComments(res.data);
          })
          .catch((err) => console.log(err));
      

        axios
          .get(`${process.env.REACT_APP_AIRDEVE_URL}posts/` + postId + "/likes/", {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((res) => {
            setLikes(res.data);
          })
          .catch((err) => console.log(err));


          axios
          .get(`${process.env.REACT_APP_AIRDEVE_URL}users/` + post.authorId , {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((res) => {
            setPoster(res.data);
            //console.log(res.data);
          })
          .catch((err) => console.log(err));
       
      })
      .catch(function (error) {
        console.log(error);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [usersData]);

  return (
    <li className="postCard-container" key={post.id}>
      {isLoading ? (
        <i class="fas fa-spinner fa-pulse"></i>
      ) : (
        <>
          <div className="postCard-left">
            <img
              src={poster && poster.profileImgUrl}
              alt=" poster-pic"
            />
          </div>
          <div className="postCard-right">
            <div className='postCard-header'>
              <div className="pseudo">
                <h3>
                  {poster && poster.username}
                </h3>
              </div>
              <span>{timestampParser(post.createdAt)}</span>
            </div>
            
            {isUpdated && (
              <div className="update-post">
                <textarea
                  defaultValue={post.caption}
                  onChange={(e) => setTextUpdate(e.target.value)}
                />
                <div className="button-container">
                  <button className="btn" onClick={updateCaption}>
                    valider
                  </button>
                </div>
              </div>
            )}

            {post.mediaUrl && (
              <img src={post.mediaUrl} alt="card-pic" className="card-pic" />
            )}
            {isUpdated === false && <p> {post.caption} </p>}
            {userData.id === post.authorId && (
              <div className="button-container">
                <div onClick={() => setIsUpdated(!isUpdated)}>

                  Modifier
                </div>
                <div
                  onClick={() => {
                    if (window.confirm("voulez-vous supprimer ce post?"))
                      deletePost();
                  }}
                >
                  Supprimer
                </div>
              </div>
            )}

            <div className="card-footer">
              <div className="comment-icon">
                <img
                  onClick={() => setShowComments(!showComments)}
                  src="../img/Comment.svg"
                  alt="comment"
                />
                <span> {comments.length} </span>
              </div>
              <LikeButton likes={likes} postId={postId} />
            </div>
            {showComments && <CardPostComment post={post} />}
          </div>
        </>
      )}
    </li>
  );
};

export default PostCard;
