import React from 'react'
import CreateEvent from '../components/CreateEvent'
import { useEffect } from 'react';


const EventCreation = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
            <br/>
            <br/>
            <CreateEvent/>
        </div>
    )
}

export default EventCreation;