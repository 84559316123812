import React from 'react';
import { useEffect } from 'react';

const Cgu = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (        
    
    <div className='cgu-form'>
        <h1> Conditions Générales </h1>

        <h2>1 – Préambule</h2>

        <h5>Les présentes Conditions Générales de vente et d’utilisation du site (ci-après
dénommées les « Conditions Générales ») ont vocation à régir l’accès et l’utilisation
des services du Site.
L’inscription ou l’utilisation du service, par un utilisateur, emporte acceptation
expresse et sans réserve les CGVUS suivantes qui prévaudront sur tout autre
document.       
        </h5>
        <h2>2 – Définitions</h2>
        <h5> 
        « Acheteur » désigne un Membre qui s’est inscrit sur le site et qui achète un ou
plusieurs Billets sur le Site ;
</h5>
<br/>
<h5>
« Association » désigne tout fonds de dotation, fondation ou association à but non
lucratif soumis à la loi du 1er juillet 1901 ;
</h5>
<br/>
<h5>« Billet » désigne la matérialisation d’un droit d’entrée à un Évènement, il permet aux
Participants de se présenter sur le lieu de l’événement pour bénéficier de la
prestation offerte par l’Organisateur ;
</h5>
<br/>
<h5>« Conditions Générales » désigne les présentes Conditions Générales d'utilisation
du Site applicables aux Visiteurs qui peuvent être consultées sur le Site par tout
Visiteur et auxquelles ces Visiteurs doivent adhérer pour pouvoir devenir Membre ;
</h5>
<br/>
<h5>« Entreprise » désigne toute personne morale de droit public ou privé (autre qu'une
Association) ;
</h5>
<br/>
<h5>« Évènement » désigne un projet évènementiel porté par un Organisateur et publié
sur le site conformément aux Conditions Générales ;
</h5>
<br/>
<h5>« STRIPE » désigne la société STRIPE Inc, une société de droit Américain, dont le siège social est situé 3180 18TH STREET CA 94110 SAN FRANCISCO
,et autorisé en qualité d'Établissement de Monnaie Électronique par la Commission de Surveillance du Secteur Financier
(CSSF) ;
</h5>
<br/>
<h5>« Membre » désigne toute personne qui s’inscrit sur le Site sous condition d'avoir
préalablement adhéré aux Conditions Générales ;
</h5>
<br/>
<h5>« Objectif de Prévente de Billets » désigne le montant de la vente de Billets
recherché par l’Organisateur pour la réalisation d'un Évènement et ouverts à la vente
auprès des Membres par l'intermédiation du Site ;
</h5>
<br/>
<h5>« Organisateur » désigne toute Entreprise, Association ou Particulier inscrit sur le
Site dans l'objectif d'apporter et de promouvoir un ou plusieurs Évènements qui
seront réalisés avec le financement de Billets vendus par l'intermédiation du Site
auprès des Acheteurs ;
</h5>
<br/>
<h5>« Participant » désigne le détenteur et utilisateur d’un ou plusieurs Billets vendus sur
le Site ;
</h5>
<br/>
<h5>« Particulier » désigne toute personne physique jouissant de pleine capacité
juridique au regard de la loi qui lui est applicable ;
</h5>
<br/>
<h5>« Période de Prévente de Billets » désigne la période pendant laquelle les Membres
peuvent acheter des Billets par l'intermédiation du Site et atteindre l'Objectif de
Prévente des Billets nécessaires à la réalisation de l’Évènement ;
</h5>
<br/>
<h5>« Période de réalisation » désigne la période pendant laquelle l’Organisateur met en
œuvre les démarches nécessaires à la réalisation de son projet, après avoir reçu le financement demandé 
</h5>
<br/>
<h5>« Société » ou « AIRDEVE » désigne la société Airdeve société par actions
simplifiée au capital de 2000 €, dont le siège social est situé au 1, Centre
commercial La Tour 93120 La Courneuve, immatriculée au registre du commerce et
des sociétés de Bobigny sous le 912 553 211. La Société exploite le Site et offre aux
Membres la possibilité d’ouvrir un Compte de Monnaie Électronique auprès de
STRIPE, d’acquérir de la Monnaie Électronique émise par ce dernier par
l’intermédiaire du Site et d’utiliser la Monnaie Électronique en vue d’acheter des
Billets auprès d’un Organisateur. La Société est mandatée à cet effet par
STRIPE pour distribuer la Monnaie Électronique auprès des utilisateurs du Site ;
</h5>
<br/>
<h5>« Visiteur » désigne toute personne qui se rend sur le Site pour consulter le contenu
et les informations publiés sur le Site sans y être inscrite ou préalablement à son
inscription sur le Site en tant que Membre.
        </h5>
        <h2>3 – Objet</h2>

        <h5> 
        L’objet des Conditions Générales est de définir les termes et conditions de mise à
dispositions des services du Site par la Société aux fins de permettre (i) aux
Organisateurs de promouvoir des Évènements sur le Site, et (ii) aux Membres de
financer la réalisation desdits Évènements par les Billets achetés par l’intermédiation
du Site.
Le paramétrage effectué pour chaque Événement par l’Organisateur donne à la
Société le pouvoir d’éditer, fabriquer, vendre et commercialiser, pour le compte des
Organisateurs, des Billets. Un billet correspond à la matérialisation d’un droit
d’entrée.
L’opération d’intermédiation ne concerne que la vente de ces droits d’entrée.
AIRDEVE acquiert ce droit d’entrée auprès de l’organisateur pour ensuite les vendre
auprès des acheteurs. L’action d’AIRDEVE se limite au stade de la
commercialisation, sans jamais intervenir au plan de l’organisation de l’événement. Sauf lorsqu'il intervient comme organisateur.
De ce fait, les Organisateurs (dont Airdeve lorsqu'il est organisateur)demeurent les seuls responsables de l’organisation de
l’événement. 
L’Organisateur est le seul à contracter avec les Participants concernant le
déroulement des Évènements. Seuls les Organisateurs sont responsables du
règlement et de la réalisation des Contrats conclus avec les Acheteurs. La Société
ne peut en aucun cas être tenue pour responsable des violations d’obligations
survenant dans le cadre de la conclusion d’un Contrat entre les Organisateurs et un
Acheteur de Billet.
        </h5>

        <h2> 4 – Intermédiation et mise en relation</h2>
        <h3> Présentation des Évènements </h3>
        <h5> Les Organisateurs présentent leurs Évènements détaillant la nature, les objectifs et
le déroulement de(s) Évènement(s) qu’ils entendent développer avec le soutien des
Membres conformément aux présentes.
Aux fins d'être considérée par Airdeve pour la promotion éventuelle sur le Site, la
présentation d'un Évènement doit notamment préciser l'Objectif de Prévente de
Billets et la Période de Prévente de Billets.
Les caractéristiques et paramètres des Évènements apportés par un Organisateur,
une fois acceptés par Airdeve et proposés sur le Site conformément aux présentes,
ne pourront plus être modifiés par l’Organisateur à l'issue de la Période de Prévente
de Billets. Toute modification majeure de l’Évènement pendant la Période de
Collecte de Prévente de Billets donne droit au remboursement des Billets achetés
par un Acheteur. Toutefois, l’Organisateur sera autorisé, durant la Période de
Réalisation, à apporter des modifications à l’Évènement qui ont pour objet
d’améliorer la réalisation des objectifs poursuivis par l’Évènement.
Il appartient à l’Organisateur de s'assurer qu'il a tous les droits nécessaires pour
publier l’Évènement sur le Site et qu'il est en conformité avec toutes les lois et tous
les règlements applicables à la conception et la réalisation de l’Évènement
concerné. En particulier, les Organisateurs s'interdisent d'apporter les Évènements
(i) en infraction avec les lois et règlements en vigueur, (ii) pouvant porter atteinte à la
dignité humaine ou à la vie privée d'une personne, (iii) contraires aux bonnes
mœurs, ou (iv) incitant ou menant à une activité illégale ou toute autre activité qui
porterait atteinte aux droits de la Société et de ses cocontractants, des Visiteurs, des
Membres et, plus généralement, de toute tierce personne.
L’Organisateur est entièrement responsable de la présentation de l’Évènement
publiée sur le Site et doit s'assurer notamment que cette présentation ne saurait.
induire les Visiteurs en erreur. L’Organisateur reconnaît que la fourniture
d'informations trompeuses, incomplètes ou erronées est susceptible d'engager sa
responsabilité à l'encontre de la Société et des Visiteurs et assume l'entière
responsabilité des conséquences résultant de toute omission ou négligence à cet
égard.</h5>

            <h3> Réalisation des Évènements </h3>
            <h5> À compter de la fin de la Période de Prévente de Billets, l’Organisateur s’engage à
fournir les prestations offertes aux Participants en conformité avec l’engagement pris
par ce dernier lors de la mise en ligne de son Évènement sur le Site.
Dans le cas où l’Organisateur ne pourrait rétribuer les Participants des prestations
promises, il s’engage expressément à les rembourser intégralement et accepte que
la Société ne puisse en aucun cas l’assister dans cette démarche.La Société n'est pas responsable des actions des Organisateurs qui restent seuls
responsables des termes qui les lient avec les Acheteurs et Participants dans le
cadre des ventes de Billets par l'intermédiation du Site.
Les Organisateurs sont seuls responsables de la vente des Billets qu'ils organisent
par l'intermédiation du Site et des prestations éventuelles qu'ils proposent aux
Membres dans le cadre des Évènements présentés sur le Site. Par conséquent, tout
risque que comportent le développement et le déroulement d'un Évènement, ainsi
que les reports et l'annulation éventuels, sont entièrement pris en charge par les
Organisateurs.
En cas d'annulation d'un Évènement dont l'Objectif de Prévente de Billets a été
atteint, l’Organisateur devra organiser des remboursements à sa seule discrétion et
la Société ne saurait être tenue responsable des remboursements des Billets vendus
par l'intermédiation du Site.
L’Organisateur garantit et s'engage à indemniser la Société contre tout dommage
subi par cette dernière, ainsi que de la protéger contre toute action en responsabilité
qui serait engagée à l'encontre de la Société par un Membre, un Visiteur ou, plus
généralement, par un tiers, au titre de la violation d'un droit quelconque résultant de
la publication de l’Évènement sur le Site ou de la réalisation de l’Évènement
présenté sur le Site.
            </h5>
            <h2> 5 – Vente et suivi des achats</h2>
            <h3> Règlement des achats </h3>
            <h5> Le règlement des Billets sera effectué à l’aide d’un des moyens de paiement
proposés pour l’Évènement concerné, qui peut être par exemple la carte bancaire
et/ou tout autre moyen de paiement déjà proposé sur le Site ou qui seront intégrés
au service dans le futur.
Règlement des achats pour les évènements situés dans la zone SEPA
(Europe) par STRIPE
Pour les évènements dont les Organisateurs disposent d'un compte bancaire dans la
zone SEPA (Single European Payments Area), la Société a choisi de faire confiance
à STRIPE pour la gestion des services de monétique par Carte Bancaire,
virement ou d'autres moyens de paiement proposés.
La Société permet ainsi à ses Utilisateurs de devenir détenteurs d'unités de monnaie
électronique qu'ils pourront affecter à l’achat de Billets ou recevoir en tant
qu’Organisateur. Pour utiliser le système de paiement par carte bancaire, virement ou prélèvement (direct debit) proposé sur le Site, l’Utilisateur doit accepter les
Conditions Particulières d’Utilisation et les Conditions Générales d’Utilisation de la
monnaie électronique de STRIPE mise à sa disposition sur le site
https://www.stripe.com. L'Utilisateur se verra ouvrir un compte de monnaie
électronique auprès de STRIPE.
En particulier, l’Utilisateur reconnaît qu’en application des dispositions des articles
L.561-2 et suivants du code monétaire et financier, relatifs à la participation des
organismes financiers à la lutte contre le blanchiment des capitaux et le financement
des activités terroristes, STRIPE et la Société en tant que distributeur mandaté
sont tenus à certaines obligations.
Ils sont notamment susceptibles de réaliser toutes les diligences nécessaires à
l’identification de l'Utilisateur. L’Utilisateur s’engage à faire toute diligence pour
permettre à la Société et STRIPE d’effectuer un examen approfondi des
opérations, à les informer de toute modification survenue au niveau de sa situation
professionnelle, patrimoniale, financière ou personnelle et à communiquer à
première demande tout document afférent à ces données lorsqu’il est titulaire d’un
Compte de Monnaie Électronique.
L'Utilisateur reconnaît que la Société et STRIPE ont mis en place des systèmes
de surveillance ayant pour finalité la lutte contre le blanchiment des capitaux et le
financement des activités terroristes.
L’Utilisateur reconnaît que la Société ou STRIPE peuvent mettre un terme ou
reporter à tout moment l’ouverture du Compte de Monnaie Électronique ou
l’exécution d’une opération en l’absence d’éléments suffisants sur son objet ou sa
nature. Il est informé qu’une opération réalisée dans le cadre des présentes peut
faire l’objet d’un signalement à la cellule de renseignement financier nationale
(TRACFIN) en cas de soupçon.
Ainsi, aucune poursuite fondée sur les articles 226-13 et 226-14 du code pénal et
aucune action en responsabilité civile ne peut être intentée ni aucune sanction
professionnelle prononcée contre la Société ou STRIPE, leurs dirigeants ou
leurs préposés qui ont fait de bonne foi les déclarations mentionnées aux articles
L.561-15 et suivants du code monétaire et financier.
Lorsque le Membre effectue un achat par Carte Bancaire, virement ou un autre
moyen de paiement proposé par STRIPE, le montant de son achat est
immédiatement débité du compte du Membre.
Si à la fin de la Période de Prévente, l’Objectif de Prévente n’a pas été atteint, la
Contribution est annulée et le montant de la Contribution est reversé sur le Compte
Bancaire du Membre, soit par annulation du paiement s'il a été effectué par carte,
soit par virement lorsque ce n'est pas possible. Dans ce dernier cas, le montant de la
Contribution est conservé sur le Compte de Monnaie Électronique du Membre,
jusqu'à ce que celui-ci communique à la Société les coordonnées bancaires
nécessaires à la bonne réalisation du virement de remboursement.
Si au terme de la Période de Prévente l’Objectif de Prévente a été atteint ou
dépassé, la somme totale des ventes de Billets sera rendue disponible sur le
Compte de Monnaie Électronique de l’Organisateur puis virée depuis le compte de monnaie électronique de l’Organisateur vers le compte bancaire de l’Organisateur et
ne pourra plus dès lors être remboursée à l’Acheteur ayant réalisé l’achat via le
système mis en place par la Société et son partenaire STRIPE.</h5>
<h2> 6 – Tarifs</h2>
<h5> L’Organisateur fixe le prix de vente des Billets.
En rémunération des prestations effectuées, la Société percevra une rémunération
assise sur le prix des billets vendus sur le Site.
Cette rémunération inclue la TVA.
La Société se réserve le droit de modifier de manière non rétroactive ses tarifs à tout
moment. Sauf mention contraire, les tarifs n’incluent pas la taxe sur la valeur ajoutée
(TVA). </h5>
<h2> 7 – Annulation ou modification d’un évènement </h2>
<h5> L’Organisateur est responsable de toute décision d’annulation, de report ou de
modification d’un événement. Il est tenu d’informer la Société en cas d’annulation, de
report ou de modification substantielle de l’Évènement.
Conformément aux textes en vigueur :
- L’annulation de l’évènement entraîne un remboursement de l’acheteur
- Toute modification majeure de l’événement (date, lieu,
programmation…) doit entraîner une possibilité de remboursement qui
pourra être offerte à l’acheteur via son espace client Airdeve. </h5>
<h2> 8 – Propriété intellectuelle </h2>
<h5> Le Site est la propriété exclusive d’AIRDEVE, ses prestataires et/ou ses fournisseurs
sont titulaires de l’intégralité des droits de propriété intellectuelle afférents au site et
son contenu. À ce titre, le contenu ne doit en aucun cas être extrait, reproduit, représenté, copié,
altéré, modifié, dénaturé, distribué, diffusé, vendu, loué, concédé ou exploité, en tout
ou en partie, de quelque manière que ce soit, sur quelque support que ce soit ou
utilisé pour créer une ou plusieurs œuvres dérivées sans l'accord express et écrit
d’AIRDEVE.
AIRDEVE accorde aux Organisateurs et Acheteurs un droit gratuit, personnel, non
exclusif et non transférable d’accès et d’utilisation du site sous réserve de leur
acceptation et de leur respect des CGVUS. </h5>

<h3> Propriété intellectuelle des Membres</h3>

<h5> Le Membre cède expressément et gratuitement à AIRDEVE le droit de reproduction
et de représentation, pour toute la durée légale de protection des droits, du contenu
qu’il met en ligne aux fins d’exploitation du site et notamment de ses fonctionnalités
promotionnelles. AIRDEVE n’est pas responsable du contenu mis en ligne par les
Membres.
Dès lors qu’un Membre rend accessible un Contenu sur le Site, il accepte que les
utilisateurs disposent à titre gratuit et à des fins personnelles de la faculté de
visualiser pendant toute la durée de l’hébergement par Airdeve.</h5>

<h2> 9 – Données personnelles – RGPD </h2>
<h5> Il est rappelé que dans le cadre de son activité, AIRDEVE est amené à collecter des
données personnelles auprès des Membres pour son compte propre, et ce, à même
d’assurer la bonne exécution des transactions (i.e. vente de Billets).
Les données à caractère personnel recueillies font l'objet de traitements dont le
responsable de traitement est AIRDEVE. Pour certains traitements, AIRDEVE est
responsable de traitement avec son prestataire de service de paiement STRIPE.</h5>

<h3> Type de données collectées </h3>
<h5> Dans le cadre de la relation contractuelle existante entre les utilisateurs du Site et la
Société, les données personnelles suivantes peuvent être collectées :  Les informations nécessaires à l'ouverture d'un compte utilisateur sur le Site
dont les champs sont obligatoires au sein du formulaire d’inscription (nom,
prénom, adresse mail). Ces données collectées sont obligatoires pour la
souscription aux services de la Société, à défaut, l'inscription aux services et
l'utilisation du Site ne pourront pas être traitées ;
</h5>
<br/>
<h5>
● Les informations nécessaires à la vérification de l'identité de l’Organisateur
lors d'une vente de Billets lancée sur le Site (documents d'identité, justificatif
de domicile, relevé d'identité bancaire, kbis, statuts de l’entreprise) ;
</h5>
<br/>
<h5>
● L'adresse email associée au compte Google lorsque l'utilisateur choisit de se
connecter via Google. Dans ce cas, la Société aura accès à votre nom et
votre photo de profil Google, et à votre adresse mail.</h5>

<h3> Finalités des traitements, durées de conservation et bases
légales</h3>
<h5> 

    ● L'exécution de la mission d'intermédiation en vente de Billets de la Société et
la gestion des utilisateurs (Membres) et celle du support des utilisateurs sur la
base de l’exécution contractuelle du service proposé par la Société sur son
Site. Les données des utilisateurs seront conservées à ce titre pendant une
durée de cinq (5) ans à compter de leur dernière connexion sur le Site ou de
leur dernier contact avec la Société, sauf demande de suppression anticipée ;
</h5>
<br/>
<h5>
    ● L'exécution des obligations légales ou règlementaires, telles que la lutte
contre le blanchiment des capitaux et le financement du terrorisme. À ce titre,
les données du client sont conservées en base d’archives chiffrée pour une
durée de cinq (5) ans à compter de la collecte des données. Les données ne
sont alors accessibles qu’à la demande d’une autorité de contrôle judiciaire
ou administrative ;
</h5>
<br/>
<h5>
    ● La prévention de la fraude sur la base de l’intérêt légitime de la Société. À ce
titre, les données du client sont conservées dans les conditions susvisées. </h5>

<h3> Sécurité des données personnelles </h3>
<h5> La Société s’engage à prendre toutes les mesures nécessaires afin d’assurer la
sécurité et la confidentialité des données personnelles des clients et notamment à
empêcher qu’elles ne soient endommagées, effacées ou que des tiers non autorisés
y aient accès.
Par ailleurs, en cas d’incident de sécurité affectant les données personnelles des
Clients (destruction, perte, altération ou divulgation), la Société s’engage à respecter
l’obligation de notification des violations de données personnelles, notamment
auprès de la CNIL. </h5>


    </div>
    );
};

export default Cgu;