import React, { useEffect, useState } from 'react';
import firebase from "../../utils/firebaseConfig";
import { useSelector } from 'react-redux';
import axios from "axios";
import { timestampParser, isEmpty } from '../Utils';
import EditDeletePostComment from './EditDeletePostComment';

const CardPostComment = ({post}) => {


    const [text, setText] = useState("");
    const [comments, setComments] = useState([]);
    const postId = post.id;
    const usersData = useSelector((state) => state.usersReducer); 
    const userData = useSelector((state) => state.userReducer);
    const [uidToken, setUidToken] = useState();


    const handleComment =(e) => {
        e.preventDefault();
        if(text){
        const params = JSON.stringify({
            "payload": text,
        });
        axios
        .post(`${process.env.REACT_APP_AIRDEVE_URL}posts/` + postId + "/comments" , params, {
        headers: {
            Authorization: `Bearer ${uidToken}`,
            'Content-Type' : 'application/json',
         }})
        .then ((res) => {
        setText('');
        //window.location.reload();
         })
        .catch((err) => console.log(err));

        }

    };

    useEffect(()=> {
       if (uidToken) {
        axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}posts/`+ postId +"/comments/" ,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                setComments(res.data);
            })
            .catch((err) => console.log(err));

       }
            
    }, [uidToken, comments, text])


useEffect(()=> {
    firebase.auth().currentUser.getIdToken(true).then(function(idToken){
        setUidToken(idToken);
          
    });
}, [])

    return (
        <div className='comments-container'>
            {comments.map((comment)=> {
                return (
                    <div className={comments.authorId === userData.id 
                    ? "comment-container client": "comment-container"}
                    key={comments.id}> 
                    <div className='left-part'> 
                        <img src={!isEmpty(usersData[0]) && usersData.map((user)=> {
                            if(user.id === comment.authorId) 
                            return user.profileImgUrl;
                            else return null;
                        }).join("")}
                        alt= "commenter-pic" />
                    </div>
                    <div className='right-part'> 
                        <div className='comment-header'>
                            <div className='pseudo'> 
                            <h3> {comment.author.username} </h3>
                            </div>
                            <span> { timestampParser(comment.createdAt)} </span>
                            
                        </div>
                        <p> { comment.payload}</p>
                        <EditDeletePostComment comment={comment}/>

                    </div>
                    </div>
                )
            })}
            {userData.id && (
                <form action="" onSubmit={handleComment} className="comment-form">
                    <input type="text" name="text" onChange={(e)=> setText(e.target.value)} value={text} placeholder="commenter"/>
                    <br/>
                    <input type="submit" value ="Envoyer" />
                </form>
            )}
            
        </div>
    );
};

export default CardPostComment;