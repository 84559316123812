import React from 'react';
import { useEffect } from 'react';
import {GoLocation, GoMail} from "react-icons/go";

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);




    return (
        <div className='contact-form'>

            <h1> Nous contacter </h1>

            
            <div className='location'>
            <GoLocation/>
            <h2> Siège social</h2>
                
             </div>
             <h3>  1 Centre commercial la Tour 93120 La Courneuve </h3>

             
            
            <div className='location'>
                <GoMail/>
                <h2> Adresse mail </h2>
                
             </div>
             <h3> contact@airdeve.com  </h3>

        </div>
    );
};

export default Contact;