import React, { useEffect, useState } from "react"; 
import firebase from "../utils/firebaseConfig";
import axios from "axios";
import { useParams } from "react-router-dom";
import { isEmpty } from "../components/Utils";
import OrderItemCard from "../components/OrderItemCard";
import Login from "../components/Routes/Login";

const MyTickets = () => {
    const {orderId} = useParams();
    const [orderItem, setOrderItem]= useState();
    const [uidToken, setUidToken] = useState();
    const [userCheck, setUserCheck] = useState(false);
    

    useEffect(()=> {

    firebase.auth().onAuthStateChanged((user) => {
            if (user) { 
    setUserCheck(true);
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        setUidToken(idToken);
        axios
              .get(`${process.env.REACT_APP_AIRDEVE_URL}orders/`+ orderId,{
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }})
              .then((res) => {
                setOrderItem(res.data);
              })
              .catch((err) => console.log(err));
        
      })
      .catch(function (error) {
        console.log(error);
      });

    }

})
  }, []);

    return (
        <div className='myTickets'>
            <h2> Mes Tickets</h2>

            {userCheck ? ( 
            
            
            <ul >
                   { orderItem && 
                   !isEmpty(orderItem.items[0])&&
                       orderItem.items.map((item) => {
                           return <OrderItemCard item = {item} key= {item.id}/>
                       })
                   }
              </ul>
       
       
                
            ) : (<Login/>)}

        


        
        </div>
    );
};

export default MyTickets;