import axios from "axios";


export const TOGGLE_LIKE_POST = "LIKE_POST";



export const toggleLike = (idToken, postId) => {

    return (dispatch) => {

    return axios
    .post(`${process.env.REACT_APP_AIRDEVE_URL}posts/`+ postId + "/like/", null, {
    headers: {
        Authorization: `Bearer ${idToken}`,
    }})
    .then ((res) => {
        console.log(res);
    })
    .catch((err) => console.log(err));
    };

    }

