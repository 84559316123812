import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = firebase.initializeApp({

//airdeve-prod

  apiKey: "AIzaSyASccBHkCJG4OAa83Z1uCSDdO-rli5QNDo",
  authDomain: "airdeve-50acf.firebaseapp.com",
  projectId: "airdeve-50acf",
  storageBucket: "airdeve-50acf.appspot.com",
  messagingSenderId: "377235255043",
  appId: "1:377235255043:web:aa76c851a80faefd2a4336",
  measurementId: "G-KN5HSQJTCH"



//airdeve-dev
/*
  apiKey: "AIzaSyDerlGcEAHm8qUIcc9iZCAZPCapAMLWi2M",
  authDomain: "airdeve-dev.firebaseapp.com",
  projectId: "airdeve-dev",
  storageBucket: "airdeve-dev.appspot.com",
  messagingSenderId: "580952803276",
  appId: "1:580952803276:web:a82d8b960225d060e4ff69"
 */ 

});


// Initialize Firebase
//firebase.initializeApp(firebaseConfig);

export const auth = firebaseConfig.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export {firebase as default };
