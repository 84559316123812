import TicketCard from "./TicketCard";
import React, { useState, useEffect } from "react";
import firebase from "../../utils/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { updateEvent } from "../../actions/allEvents.actions";
import CreateTicket from "../CreateTicket";
import { isEmpty } from "../Utils";
import axios from "axios";
import { dateToISOStringParser } from "../Utils";
import ReactHtmlParser from 'html-react-parser';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {HiLocationMarker} from "react-icons/hi"
import {MdDateRange,MdOutlineCategory} from "react-icons/md"


const Details = (props) => {
  let eventId = props.event.id;
  let eventUid = props.event.uid;
  const userData = useSelector((state) => state.userReducer);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [dateApproximation, setDateApproximation] = useState();

  const [location, setLocation] = useState();
  const [category, setCategory] = useState();
  const [capacity, setCapacity] = useState();
  const [date, setDate] = useState();
  const dispatch = useDispatch();
  const [uidToken, setUidToken] = useState();
  const [eventDetail, setEventDetail] = useState();
  const [ticketTypes, setTicketTypes] = useState([]);
  const [loginPopUp, setLoginPopUp] = useState(false);

  const dict = [
    { value:  "music",
      label:  "Musique",
   },
     {value:  "sport",
     label:  "Evènement Sportif",
   },
     {value:  "hobbies",
     label:  "Loisir",
   },
     {value:  "art",
     label:  "Exposition et vernissage"
   },
     {value:  "spectacles",
     label:  "Théâtre et spectacle",
   },
     {value:  "charity",
     label:  "Evènement associatif",
   },
    {value:  "conferences",
    label:  "Conférences et Ateliers",
   }
   ]

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_AIRDEVE_URL}events/` + eventUid
      )
      .then((res) => {
        setEventDetail(res.data);
      })
      .catch((err) => console.log(err));
  
  }, []);     

  useEffect(() => {
            axios
              .get(
                `${process.env.REACT_APP_AIRDEVE_URL}events/` + eventId + "/tickets/"
              )
              .then((res) => {
                setTicketTypes(res.data);
              })
              .catch((err) => console.log(err));
          
  }, [dispatch]);


  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          setUidToken(idToken);
        })
        .catch(function (error) {
          console.log(error); });
      }
      
    });

    },[]);

  const updateEvent= (uidToken, eventId, data) => {
    axios 
    .post(`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId +"/", data ,  {
        headers: {
            Authorization: `Bearer ${uidToken}`,
    }})
    .then (() => {
      axios
      .get(
        `${process.env.REACT_APP_AIRDEVE_URL}events/` + eventUid
      )
      .then((res) => {
        setEventDetail(res.data);
      })
      .catch((err) => console.log(err));
    })
    .catch((err) => console.log(err));

  }

  const updateName = () => {
    const data = new FormData();
    data.append("name", name);
    updateEvent(uidToken, eventId, data);
  };
  const updateDescription = () => {
    const data = new FormData();
    data.append("description", description);
    updateEvent(uidToken, eventId, data);
  };
  const updateCategory = () => {
    const data = new FormData();
    data.append("category", category);
    updateEvent(uidToken, eventId, data);
  };
  const updateCapacity = () => {
    const data = new FormData();
    data.append("capacity", capacity);
    updateEvent(uidToken, eventId, data);
  };
  const updateDateApproximation = () => {
    const data = new FormData();
    data.append("dateApproximation", dateApproximation);
    updateEvent(uidToken, eventId, data);
  };
  const updateDate = () => {
    const data = new FormData();
    data.append("date", date.concat("T00:00:00.000Z"));
    updateEvent(uidToken, eventId, data);
  };
  const updateLocation = () => {
    const data = new FormData();
    data.append("location", location);
    updateEvent(uidToken, eventId, data);
  };

  return (
   
    <div>
      <div className="details">
      <h5> Tickets </h5>
      <br/>
        <ul className="ticket-types">
          {!isEmpty(ticketTypes[0]) &&
            ticketTypes.map((ticket) => {
              return (
                <TicketCard
                  ticket={ticket}
                  eventDetail={eventDetail}
                  key={ticket.id}
                />
              )
            })}
        </ul>
        <div className="eventPresentation">
          <div className="eventDescription">
            <h5> À propos de l'évènement </h5>
            <br/>
            <h6> {eventDetail && ReactHtmlParser(eventDetail.description)} </h6>
          </div>
          

        </div>

        {loginPopUp? (
          <div> 
          <br/>
          <h3> Vous n'êtes pas connecté! Merci de vous connecté pour précommander votre billet. </h3>
          </div>
        ):(

        <div>
        {eventDetail && !isEmpty(eventDetail.organizer) &&
          userData.id === eventDetail.organizer.id && (
            <div className="eventEdit">
              <h2> Modifier l'évènement </h2>

              <ul>
                <div className="form">
                  <ul>
                    <p> Nom de l'évènement : {eventDetail.name} </p>
                    <input
                      type="text"
                      defaultValue={eventDetail.name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment modifier le nom de l'évènement?"
                            )
                          ) {
                            updateName();
                          }
                        }}
                      >
                        {" "}
                        Modifier le nom{" "}
                      </button>
                    </div>
                  </ul>
                </div>
                <div className="editor">
                  <ul>
                    <div className="title"> Description de l'évènement </div>
                    <CKEditor
                      editor= {ClassicEditor}
                      data = {eventDetail.description}
                      onChange ={(e, editor) => {
                      const data = editor.getData()
                      setDescription(data)
                  }}
                  />
                    <div>
                      <button className="validateBtn"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment modifier la description?"
                            )
                          ) {
                            updateDescription();
                          }
                        }}
                      >
                        {" "}
                        Modifier la description{" "}
                      </button>
                    </div>
                  </ul>
                </div>
                <div className="form">
                  <ul>
                    <p> Catégorie : {eventDetail.category.name} </p>
                    <select onChange={(e) => setCategory(e.target.value)}>
                      <option> Musique</option>
                      <option> Loisir</option>
                      <option> Exposition et vernissage</option>
                      <option> Evènement sportif</option>
                      <option> Evènement associatif</option>
                      <option> Théâtre et spectacle</option>
                      <option> Conférences et Ateliers</option>
                    </select>
                    <div>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment modifier la catégorie?"
                            )
                          ) {
                            updateCategory();
                          }
                        }}
                      >
                        {" "}
                        Modifier la catégorie{" "}
                      </button>
                    </div>
                  </ul>
                </div>
                <div className="form">
                  <ul>
                    <p> Capacité : {eventDetail.capacity} participants </p>
                    <input
                      type="number"
                      placeholder="Nombre maximum de particpants"
                      defaultValue={eventDetail.capacity}
                      onChange={(e) => setCapacity(e.target.value)}
                    />
                    <div>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment modifier le nombre de participants"
                            )
                          ) {
                            updateCapacity();
                          }
                        }}
                      >
                        {" "}
                        Modifier la capacité{" "}
                      </button>
                    </div>
                  </ul>
                </div>

                <div className="form">
                  <ul>
                    <p> Période de tenu : {eventDetail.dateApproximation} </p>
                    <input
                      type="text"
                      defaultValue={eventDetail.dateApproximation}
                      onChange={(e) => setDateApproximation(e.target.value)}
                    />
                    <div>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment modifier la période de tenue de votre évènement?"
                            )
                          ) {
                            updateDateApproximation();
                          }
                        }}
                      >
                        {" "}
                        Modifier la période{" "}
                      </button>
                    </div>
                  </ul>
                </div>
                <div className="form">
                  <ul>
                    <p>
                      {" "}
                      Date de l'évènement:{" "}
                      {eventDetail.date == null
                        ? "Aucune date précisée"
                        : dateToISOStringParser(eventDetail.date)}{" "}
                    </p>
                    <input
                      type="datetime-local"
                      onChange={(e) => setDate(e.target.value)}
                    />
                    <div>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment modifier la date de tenue de l'évènement?"
                            )
                          ) {
                            updateDate();
                          }
                        }}
                      >
                        {" "}
                        Modifier la date{" "}
                      </button>
                    </div>
                  </ul>
                </div>
                <div className="form">
                  <ul>
                    <p> Lieu de tenu : {eventDetail.location} </p>
                    <input
                      type="text"
                      defaultValue={eventDetail.location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    <div>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment modifier le lieu de tenu de votre évènement?"
                            )
                          ) {
                            updateLocation();
                          }
                        }}
                      >
                        {" "}
                        Modifier le lieu{" "}
                      </button>
                    </div>
                  </ul>
                </div>
              </ul>
            </div>
          )}
       
        {eventDetail && !isEmpty(eventDetail.organizer) &&
          userData.id === eventDetail.organizer.id && (
            <div>
              <CreateTicket event={eventDetail} />
            </div>
          )}
        </div>

)}

      <br/>
      <br/>
      <div class="securePayment">
            <img src="../img/payment.png" alt="secure_payment"/>
      </div>

      </div>

      
    </div>
  )
}

export default Details;
