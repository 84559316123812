import axios from "axios";


export const GET_ALLEVENTS = "GET_ALLEVENTS";
export const CREATE_EVENT = "CREATE_EVENT";
export const UPLOAD_EVT_PICTURE = "UPLOAD_EVT_PICTURE";
export const UPDATE_EVENT = "UPDATE_EVENT";

export const getAllEvents = ()=> {
    return (dispatch) => {
        return axios
            .get(`${process.env.REACT_APP_AIRDEVE_URL}events`)
            .then((res) => {
        
                dispatch( {type: GET_ALLEVENTS, payload:res.data})
            })
            .catch((err) => console.log(err));
    };
};


export const createEvent = (uidToken,data) => {
    return (dispatch) => {
        return axios ({
            method: "post",
            data: data,
            url: `${process.env.REACT_APP_AIRDEVE_URL}events/new/`,
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }
    })
            .then((res) => {
                alert("Evènement créé")
        
                dispatch( {type: CREATE_EVENT, payload:res.data})
            })
            .catch((err) => alert("Evènement non créé"));


    };
    
};

export const uploadEventPicture = (uidToken, data, eventId) => {
    return (dispatch) => {
        return axios 
        .post(`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId +"/", data ,  {
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }})
        .then ((res) => {
            return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}events/` ,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                dispatch({type: GET_ALLEVENTS, payload: res.data});
                window.location.reload(false);
            })
        })
        .catch((err) => console.log(err));
        
    };
};

export const updateEvent = (uidToken, eventId, data) => {
    return (dispatch) => {
        return axios 
        .post(`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId +"/", data ,  {
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }})
        .then ((res) => {
            return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                dispatch({type: GET_ALLEVENTS, payload: res.data});
                
                
            })
        })
        .catch((err) => console.log(err));
        
    };
};

