import axios from "axios";
import React, { useEffect, useState } from 'react';
import firebase from "../../utils/firebaseConfig";
import { isEmpty } from '../Utils';
import OrderCard from "../OrderCard";

const MyWallet = ({userData}) => {

    const [orders, setOrders] = useState([]);

    useEffect(()=> {
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
          
            !isEmpty(userData) && (
                axios
                .get (`${process.env.REACT_APP_AIRDEVE_URL}users/me/orders/` ,{
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }})
                .then ((res) => {
                    setOrders(res.data);
                })
                .catch((err) => console.log(err)));
              
        });
    }, [userData])


    return (
        <div className='myWallet'>
            <h2> Vos commandes</h2>
            <ul >
                {
                !isEmpty(orders[0])&&
                    orders.map((orderItem) => {
                        return <OrderCard orderItem = {orderItem} key= {orderItem.id}/>
                    })
                }
           </ul>
        </div>
    );
};

export default MyWallet;