import React, { useEffect, useState } from "react";
import ProfileDetails from '../components/ProfileDetails';
import firebase from "../utils/firebaseConfig";
import { useLocation } from "react-router-dom";
import axios from "axios";

const OtherProfil = () => {

    const [uidToken, setUidToken] = useState('');
    const [otherUserData, setOtherUserData] = useState('');
    let location = useLocation();
    let eventDetail = location.state.eventData;

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(() => {

        firebase.auth().onAuthStateChanged((user) => { 
            if (user) {

        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
            setUidToken(idToken);
            axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}users/`+ eventDetail.organizer.id ,{
            headers: {
                Authorization: `Bearer ${idToken}`,
            }})
            .then ((res) => {
                setOtherUserData(res.data);
            })
            .catch((err) => console.log(err));

          }).catch(function(error) {
            console.log(error);
          });

        }
    });
        
      },[uidToken]);

    return (
        <div>
            <ProfileDetails userData = {otherUserData}/>
        </div>
    );
};

export default OtherProfil;