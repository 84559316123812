import ReadEvent from "../components/ReadEvent";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";



const Home = () => {

  var settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  var settings1 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [mobile, setMobile] = useState(true);

  const changeHome = () => {
    if (window.innerWidth <= 1200) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  useEffect(() => {
    changeHome();
    window.addEventListener("resize", changeHome);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  return (
    <div>
      <div className="home"> 
        <div class="banner">
          <div className="line">
            <div className="text">
              <h2> Décidez-le ! Vivez des moments inédits. </h2>
              <h5>
                {" "}
                Vous financez l'organisation de vos évènements préférés 
                en achetant des tickets en prévente.{" "}
              </h5>
            </div>
            <div className="image">
              <img src="../img/img_Site_web/bannerImg.png" alt="Photo_1" />
            </div>
          </div>
        </div>


        {/*<div className="homeVideo">
        <iframe width="1120" height="630" src="https://www.youtube.com/embed/ml98TJVYjBQ?controls=0" 
        title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>*/}
 {/*
          <div>
            <ReadEvent />
</div> */}
        
          <div className="quote2">
                <h1> Ils nous accompagnent</h1>
            </div>
            
          <div className="slider">
              <Slider {...settings1}>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner5.png" alt="Photo_1"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner1.png" alt="Photo_4"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner6.png" alt="Photo_2"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner2.png" alt="Photo_3"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner4.png" alt="Photo_2"/>
                    </div>
                    <div class="image">
                        <img src="../img/img_Site_web/Partner3.png" alt="Photo_3"/>
                    </div>
              </Slider>
      </div>

            

        <div class="land2">
          <div className="line">
            <div className="text">
              <h2> Découvrez des évènements originaux. </h2>
              <h5>
                {" "}
                Les organisateurs proposent des évènements qui vont vous
                intéresser: tournoi, concert, pièce de théâtre,
                spectacle, exposition, vernissage...{" "}
              </h5>
            </div>
            <div className="image">
              <img src="../img/img_Site_web/HomePicture1.png" alt="Photo_1" />
            </div>
          </div>
          {mobile ? (
            <div className="line">
              <div className="image">
                <img src="../img/img_Site_web/HomePicture2.png" alt="Photo_2" />
              </div>
              <div class="text">
                <h2>
                  {" "}
                  Financez l'organisation des évènements qui vous intéressent.{" "}
                </h2>
                <h5>
                  {" "}
                  En achetant des tickets en prévente, vous permettez à
                  l'organisateur de valider sa campagne et d'obtenir le
                  financement nécessaire à l'organisation de l'évènement.
                </h5>
              </div>
            </div>
          ) : (
            <div className="line">
              <div class="text">
                <h2>
                  {" "}
                  Financez l'organisation des évènements qui vous intéressent.{" "}
                </h2>
                <h5>
                  {" "}
                  En achetant des tickets en prévente, vous permettez à
                  l'organisateur de valider sa campagne et d'obtenir le
                  financement nécessaire à l'organisation de l'évènement.
                </h5>
              </div>

              <div className="image">
                <img src="../img/img_Site_web/HomePicture2.png" alt="Photo_2" />
              </div>
            </div>
          )}

          

          <div className="line">
            <div className="text">
              <h2>
                {" "}
                Rencontrez des personnes qui partagent les mêmes centres
                d'intérêts que vous.
              </h2>
              <h5>
                {" "}
                Rencontrez et échangez des commentaires, des publications avec
                des personnes qui participent aux mêmes évènements que vous.{" "}
              </h5>
            </div>
            <div className="image">
              <img src="../img/img_Site_Web/HomePicture3.png" alt="Photo_3" />
            </div>
          </div>
          { mobile ? (
          <div className="line">
            <div className="image">
              <img src="../img/img_Site_web/HomePicture4.png" alt="Photo_4" />
            </div>
            <div className="text">
              <h2>
                {" "}
                Suivez un organisateur et découvrez tous les évènements qu'il
                organise.
              </h2>
              <h5>
                {" "}
                Vous pouvez vous abonner aux comptes de vos organisateurs
                favoris. Vous recevrez des notifications dès que ces derniers
                lanceront de nouvelles campagnes.{" "}
              </h5>
            </div>
          </div> ) : (

          <div className="line">
            <div className="text">
              <h2>
                {" "}
                Suivez un organisateur et découvrez tous les évènements qu'il
                organise.
              </h2>
              <h5>
                {" "}
                Vous pouvez vous abonner aux comptes de vos organisateurs
                favoris. Vous recevrez des notifications dès que ces derniers
                lanceront de nouvelles campagnes{" "}
              </h5>
            </div>
            <div className="image">
              <img src="../img/img_Site_web/HomePicture4.png" alt="Photo_4" />
            </div>
          </div>)}
        </div> 
{/*
        <div className="quote2">
                <h1> Notre équipe à votre écoute</h1>
            </div>
            
          <div className="team-slider">
              <Slider {...settings2}>
                    <div class="image">
                        <img src="../img/team/JFPP.png" alt="PP1"/>
                        <h2> Jean-François SIMONNET</h2>
                        <h3> Responsable/ Technologie</h3>
                    </div>
                    <div class="image">
                        <img src="../img/team/Tomi.png" alt="PP2"/>
                        <h2> Tomi YESSINOU</h2>
                        <h3> Responsable/ Partenariat & Evènementiel</h3>
                    </div>
                    <div class="image">
                        <img src="../img/team/Amelie.png" alt="PP3"/>
                        <h2> Amélie RICHARD </h2>
                        <h3> Responsable/ Communication & Evènementiel</h3>
                    </div>
                    <div class="image">
                        <img src="../img/team/Mohamad.png" alt="PP4"/>
                        <h2> Mohamad AL DARWICH </h2>
                        <h3> Responsable/ Production Multimédia</h3>
                    </div>
                  
              </Slider>
          </div> */}

      <div className="mailto">
        <a href="mailto:contact@airdeve.com">
          Contactez-nous
        </a>
      </div>
        
          
      </div>
    </div>
  );
};

export default Home;
