import React from 'react';
import { NavLink } from 'react-router-dom';

const NoEvent = () => {
    return (
        <div className='notFound'>
            <br/>
            <h1> Cet événement n'existe pas </h1> 
           <br/>
           <h2> Oups! Vous vous êtes trompé de chemin. </h2> 
           <br/>
           <h3> L'événement que vous recherchez n'existe pas ou a été supprimé !  </h3>
           <br/>
           <div className= "btn">

           <NavLink exact to="/" activeClassName="nav-active">
                Page d'accueil
           </NavLink>

           </div>
           
           <br/>
           <br/>
           <br/>
            
        </div>
    );
};

export default NoEvent;