import { CREATE_EVENT, GET_ALLEVENTS,UPLOAD_EVT_PICTURE, UPDATE_EVENT } from "../actions/allEvents.actions";

const initialState = {};

export default function allEventsReducer(state = initialState, action){

    switch (action.type){
        case GET_ALLEVENTS:
            return action.payload;
        case CREATE_EVENT:
            return action.payload;
        case UPLOAD_EVT_PICTURE:
                return state.map((event) => {
                    if (event._id === (action.payload.id+1)) {
                      return {
                        ...event,
                        coverImgUrl: action.payload.coverImgUrl,
                      };
                    } else return event;
                  });
                
        case UPDATE_EVENT:
            return action.payload;
        
            
        default:
            return state;
    }
}