import axios from "axios";
import React, { useEffect, useState } from 'react';
import firebase from "../../utils/firebaseConfig";
import { isEmpty } from '../Utils';
import OtherEventCard from "../OtherEventCard";

const MyCreations = ({userData}) => {

    const [events, setEvents] = useState([]);

    useEffect(()=> {
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
            !isEmpty(userData) && (
                axios
                .get (`${process.env.REACT_APP_AIRDEVE_URL}users/`+ userData.id +"/events/" ,{
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }})
                .then ((res) => {
                    setEvents(res.data);
                })
                .catch((err) => console.log(err)));
            
              
        });
    }, [userData])
    return (
        <div className="myCreations">
            <h2> Vos évènements </h2>
            <ul >
                {
                !isEmpty(events[0])&&
                    events.map((eventItem) => {
                        return <OtherEventCard eventItem = {eventItem} key= {eventItem.id}/>
                    })
                }
           </ul>
        </div>
    );
};

export default MyCreations;