import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import firebase from "../../utils/firebaseConfig";
import { isEmpty } from "../Utils";
import axios from "axios";
import { timestampParser } from '../Utils';
import {HiArrowCircleLeft, HiArrowCircleRight} from "react-icons/hi";
import { NavLink } from "react-router-dom";

const Sales = (props) => {


    let eventDetail = props.event;
    const userData = useSelector((state) => state.userReducer);
    const[ sales, setSales] = useState();
    const [uidToken, setUidToken] = useState();
    const [loginPopUp, setLoginPopUp] = useState(false);
    const [cursorCount, setCursorCount] = useState(false);



    const nextPage = () => {
      if (sales.length > 0) {

        axios
        .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventDetail.id +"/buyers?cursor=" + sales[(sales.length -1)].id ,{
            headers: {
            Authorization: `Bearer ${uidToken}`,
        }})
        .then ((res) => {
            setSales(res.data);
            setCursorCount(sales[(sales.length-1)].id);
            
        })
        .catch((err) => console.log(err));
      }

      else {
        prevPage();
      }
     



    }
    const prevPage = () => {
      axios
                .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventDetail.id +"/buyers" ,{
                    headers: {
                    Authorization: `Bearer ${uidToken}`,
                }})
                .then ((res) => {
                    setSales(res.data);
                })
                .catch((err) => console.log(err));



    }


    useEffect(() => {

          axios
                .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventDetail.id +"/buyers" ,{
                    headers: {
                    Authorization: `Bearer ${uidToken}`,
                }})
                .then ((res) => {
                    setSales(res.data);
                })
                .catch((err) => console.log(err));

            
      }, [uidToken,eventDetail]);

      useEffect(() => {

        firebase.auth().onAuthStateChanged((user) => {
          if (user) { 
        
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            setUidToken(idToken);
          })
          .catch(function (error) {
            console.log(error);
    
          });
    
        } else {
          setLoginPopUp(true);
        }
      });
      }, []);


    return (
        <div className="sales">
        {
                  loginPopUp?(
                      <div> 
            <br/>
            <h3> Vous n'êtes pas connecté! Merci de vous connecté pour voir les ventes. </h3>
            </div>
  
                  ):(

        

                    
  
        <div> 
        
        {!isEmpty(eventDetail.organizer) && userData.id === eventDetail.organizer.id && (
          
          <div> 
          <div className="scanButton"> 
          <NavLink exact to="/CheckTicket">
              Scannez un billet
              </NavLink> 
          </div>
          <div className="sales-table">  
         <table>
         
         <thead> 
             <tr>
                 <th> Nom</th>
                 <th> Mail</th>
                 <th> Nombre de Place</th>
             </tr>
         </thead>
         <tbody>
            {sales &&
             sales.map((sale) => {
               return <tr>
                <th> {sale.name} </th>
                <th> {sale.email} </th>
                <th> {sale.ticketsCount} </th>
            </tr>;
             })}
         </tbody>

     </table>
     </div>
     </div>
          
        )}
        </div>
        )
      }
      <div className="direction">
        <div className="icon">

          <button onClick={prevPage}>
            <HiArrowCircleLeft/>
          </button>

          <button onClick={nextPage}>
            <HiArrowCircleRight/>
          </button>
          
        
        

        </div>
        
      </div>
  
      </div>
    );
};

export default Sales;