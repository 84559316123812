import firebase from '../utils/firebaseConfig';
import React, { useState, useEffect } from 'react'
import axios from 'axios';

const CreateTicket = (props) => {

    let eventdetail = props.event;

    const [eventId, setEventId] = useState('');
    const [nameTicket, setNameTicket] = useState('');
    const [description, setDescription] = useState('');
    const [numberOfTicket, setNumberOfTicket] = useState('');
    const [ticketPrice, setTicketPrice] = useState(10);
    const [ticketTotal, setTicketTotal] = useState("");
    const [ticketAbsorbFee, setTicketAbsorbFee] = useState(false);
    const [uidToken, setUidToken]= useState();
    const [free, setFree] = useState(false);

    const acceptAbsorbFees = ()=> {
        setTicketAbsorbFee(!ticketAbsorbFee);
    
      }

    const changeAccess = ()=> {
        setFree(!free);
    
      }
    const simulate = (e) =>{
        setTicketPrice(e.target.value);
    
      }
      useEffect(()=> {
        axios 
          .get(`${process.env.REACT_APP_AIRDEVE_URL}ticket-types/simulate?eventId=`+eventId+"&cost="+ticketPrice+"&absorbFee="+ticketAbsorbFee,

          {
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }}
          )
            
            .then ((res) => {
              setTicketTotal(res.data.cost.majorValue)
             })
            .catch((err) => {
              console.log(err);
    
            }
              );
    }, [ticketPrice])

    const createTicket = async() => { 
        if (nameTicket && numberOfTicket && description && ticketPrice) {
           

            await axios 
            .post(`${process.env.REACT_APP_AIRDEVE_URL}ticket-types/new/`, 
            {
                eventId: eventId,
                name: nameTicket,
                quantity: numberOfTicket,
                description:description,
                cost: ticketPrice,
                hidden: ticketPrice,
                absorbFee: ticketAbsorbFee,
                free : free,
            }, 
            {
              headers: {
                  Authorization: `Bearer ${uidToken}`,
               }})
              
              .then ((res) => {
                alert("Votre ticket a bien été créé")
                //window.location.reload(false);
               })
              .catch((err) => console.log(err));
         
        
              setNameTicket("");
              setNumberOfTicket("");
              setDescription("");
              setTicketPrice("");
              setTicketAbsorbFee(false);
      
          } else {
            alert("veuillez remplir toutes les informations pour créer votre évènement")
          }
            

    }

    useEffect(() => {
        setEventId(eventdetail.id);
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
    
            setUidToken(idToken); 

             }).catch(function(error) {
                console.log(error);
            });
    
        },[]);
         
    return (
        <div className="ticket">
            <br/>
            <h2> CREATION DE TICKET</h2>

            <ul className="ticketGroup">
                <li className= "ticketLeft">
                    <div className="form">
                        <textarea  type="text" placeholder= "Description du ticket" value={description} onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </li>

                <ul className = "ticketRight">
                    <li>
                        <div className="form">
                        <input  type="text" placeholder= "Nom du ticket" value={nameTicket} onChange={(e) => setNameTicket(e.target.value)}
                        />
                        </div>
                     </li>

                    <li>
                        <div className="form">
                        <input  type="number" min= "1" placeholder= "Nombre de tickets à vendre" value={numberOfTicket} onChange={(e) => setNumberOfTicket(e.target.value)}
                        />
                    </div>
                    </li>
                    <li>
                       { (free === false) && <div className="form">
                          
                        <input  type="number" min="1" id="price " placeholder= "Prix d'un ticket" value={ticketPrice} onChange={(e) => {simulate(e)}}
                        />
                        <h5> Le participant paiera: {ticketTotal} €</h5>
                      
                        </div>
                        }
                        <div className="checkboxForm">
                        <input
                          type="checkbox" 
                          onChange={changeAccess}>
                        </input>
                         Le ticket est gratuit
                        </div>
                        { (free === false) &&
                          <div className="checkboxForm">
                          <input
                            type="checkbox" 
                            onChange={acceptAbsorbFees}>
                          </input>
                           Prise en charge des frais (Les frais seront déduit du montant payé par le participant)
                          </div>
                        }
                        
                    </li>

                </ul>

            </ul>

            <div>
                <button onClick={createTicket}> Créer un ticket </button>
             </div> 
             
        </div>
        
    );
};

export default CreateTicket;