import { GET_EVENT_POSTS } from "../actions/eventPosts.actions";

const initialState = {};

export default function eventPostsReducer (state = initialState, action) {
    switch (action.type) {
        case GET_EVENT_POSTS:
            return action.payload;
        default:
            return state;
    }
}