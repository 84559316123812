import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, timestampParser} from '../Utils';
import firebase from "../../utils/firebaseConfig";
import axios from "axios";

const NewPostForm = ({event}) => {
    const [isLoading, setIsLoading] = useState(true);
    const userData = useSelector((state) => state.userReducer);
    const [message,setMessage] = useState("");
    const [postPicture, setPostPicture]= useState(null);
    const [file, setFile]= useState();
    const [uidToken, setUidToken] = useState('');
    

    const handlePicture = (e) => {
       setPostPicture(URL.createObjectURL(e.target.files[0]));
       setFile(e.target.files[0]);
    };

    const handlePost = async ()=> {
        if (message && file && file.size<1e6 ) {
            const data = new FormData();
            data.append('eventId', event.id);
            data.append('caption', message);
            data.append('media', file);

        await axios 
        .post(`${process.env.REACT_APP_AIRDEVE_URL}posts/new`, data, {
        headers: {
            Authorization: `Bearer ${uidToken}`,
         }})
        .then ((res) => {
        //window.location.reload(false);
        cancelPost();
         })
        .catch((err) => console.log(err));


        } else {
            alert("veuillez entrer un message et une photo (taille max 1Mo)")
        } 

    };

    const cancelPost = () => {
        setMessage("");
        setPostPicture("");
        setFile("");

    }


    useEffect(()=> {
        if (!isEmpty(userData)) setIsLoading(false);
        firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        setUidToken(idToken)
      })
      .catch(function (error) {
        console.log(error);
      });

    },[userData])

    return (
        <div className='post-container'>
            {isLoading? (
                <i className = "fas fa-spinner fa-pulse"> </i>
            ) : (
        <>
            <div className='post-form'> 
            <textarea name='message' id='message' placeholder='Nouvelle publication' onChange={(e)=> setMessage(e.target.value)}
            value={message}
            />
            { message || postPicture ? (
                <li className='card-container'> 
                    <div className='card-left'>
                        <img src= {userData.profileImgUrl} alt="user-pic" />
                    </div>
                    <div className='card-right'>
                        <div className='card-header'>
                            <div className='pseudo'>
                                <h3> {userData.username} </h3>
                            </div>
                            <span> {timestampParser(Date.now())} </span>
                        </div>
                        <div className='content'>
                            <p> {message} </p>
                            <img src = {postPicture} alt="" />
                        </div>
                    </div>
                
                </li>): null}
            </div> 
            <div className='footer-form'>
                <div className='icon'>
                    <>
                    <img src='../img/picture.svg' alt='post-pic' />
                    <input type="file" id='file-upload' name='file' accept='.jpg, .jpeg, .png' onChange={(e)=> handlePicture(e)} />
                    </>
                </div>
                <div className='btn-send'> 
                {
                    message || postPicture ? (
                <button className='cancel' onClick={cancelPost}> Annuler </button>): null}
                <button className='send' onClick={handlePost}> Envoyer </button>
                </div>

            </div>
        </>

            )}
            
        </div>
    );
};

export default NewPostForm;