import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import firebase from "../../utils/firebaseConfig";
import { isEmpty } from "../Utils";
import NewPostForm from "./NewPostForm";
import PostCard from "./PostCard";
import axios from "axios";

const Posts = (props) => {
  let eventDetail = props.event;
  const userData = useSelector((state) => state.userReducer);
  //const posts = useSelector((state) => state.eventPostsReducer);
  const[ posts, setPosts] = useState();
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [uidToken, setUidToken] = useState();


  useEffect(() => {

    if (uidToken) {
      axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventDetail.id +"/posts/" ,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                setPosts(res.data);
            })
            .catch((err) => console.log(err));

    }
        
  }, [uidToken, eventDetail, posts]);

  useEffect(() => {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) { 
    
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        setUidToken(idToken);
      })
      .catch(function (error) {
        console.log(error);

      });

    } else {
      setLoginPopUp(true);
    }
  });
  }, []);

  return (

    
    <div>
      {
                loginPopUp?(
                    <div> 
          <br/>
          <h3> Vous n'êtes pas connecté! Merci de vous connecter pour voir et faire des publications. </h3>
          </div>

                ):(

      <div> 
      {!isEmpty(eventDetail.organizer) && userData.id === eventDetail.organizer.id && (
        <div className="new-post-container">
            <NewPostForm event = {eventDetail} />
        </div>
      )}
      <ul>
        {posts &&
          posts.slice(0).reverse().map((post) => {
            return <PostCard post={post} key={post.id} />;
          })}
      </ul>

      </div>
      )
    }

    </div>
  );
};

export default Posts;
