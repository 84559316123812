import React from 'react';
import {dateParser } from './Utils';
import ReactHtmlParser from 'html-react-parser';
import { NavLink } from 'react-router-dom';

const OtherEventCard = (props) => {
    
    const {eventItem} = props;
    
    const setEventState = (state) => {
        switch(state) 
        {
          case("FAILED"):
          return  "Echec"
          case("CREATED"):
          return  "Bientôt en cours"
          case("OPEN"):
          return  "En cours"
          case("SUCCEEDED"):
          return  "Succès"
          case("CANCELED"):
          return  "Terminée"

        }
   };

    
    return (
       
    
        
        <div className="eventCard">
            <NavLink className="eventLink" exact to = { { pathname:"./events/"+ eventItem.uid, state: {eventData: eventItem} }}>
            <img src={eventItem.coverImgUrl} alt="Affiche"/>
            </NavLink>
            <div className="cardText">
                <li>
                <span className="nameEvent">{eventItem.name}</span>
                </li>
                <div className='dateLocation'>
                <span className="dateEvent"> { eventItem.date === null ? eventItem.dateApproximation: dateParser(eventItem.date)}</span>
                <span className="eventLocation"> {"  à " + eventItem.location}</span>
                </div>
                
                <div className='goal'> 
                <p> Objectif de la campagne: </p>
                <span>  { setEventState(eventItem.status)}</span>
                </div>
                
                <div className='tag'> 
                <p> Fin de la campagne: </p>
                <span>  { dateParser(eventItem.salesEnd)} </span>
                </div>
                
                
            </div>

        </div>
        
        
        
    );
};

export default OtherEventCard;