import axios from "axios";
export const GET_FOLLOWERS = "GET_FOLLOWERS";




export const getFollowers = (idToken, userId, followersCursor) => {


    return (dispatch) => {
        return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}users/`+ userId +"/followers/" ,{
            headers: {
                Authorization: `Bearer ${idToken}`,
            }})
            .then ((res) => {
                dispatch({type: GET_FOLLOWERS, payload: res.data})
            })
            .catch((err) => console.log(err));
};
};