import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import allEventsReducer from "./allEvents.reducer";
import ticketsReducer from "./tickets.reducer" ;
import usersReducer from "./users.reducers";
import followersReducer from "./followers.reducer";
import followingReducer from "./following.reducer";
import eventPostsReducer from "./eventPosts.reducer";


export default combineReducers ({
    allEventsReducer,
    userReducer,
    ticketsReducer,
    usersReducer,
    followersReducer,
    followingReducer,
    eventPostsReducer,
    
    
});