import {
    GET_FOLLOWING
    
  } from "../actions/following.actions";
  
  const initialState = {};
  
  export default function followingReducer(state = initialState, action) {
    switch (action.type) {
    case GET_FOLLOWING:
        return action.payload;
      default:
        return state;
    }
  }