import React, { useState, useEffect } from "react";
import firebase from "../../utils/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { changeToOrganizer, changeToUser, updateMyInfo, getUser } from "../../actions/user.actions";

const MyInfo = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const dispatch = useDispatch();
  const [uidToken, setUidToken] = useState();
  const [userData, setUserData] = useState();
  //const userData = useSelector((state) => state.userReducer);

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        setUidToken(idToken);
        axios
              .get(`${process.env.REACT_APP_AIRDEVE_URL}users/me/`,{
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }})
              .then((res) => {
                setUserData(res.data);
              })
              .catch((err) => console.log(err));
        //dispatch(getUser(idToken)); 
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const updateMyInfo = (uidToken, data) => {
    axios 
        .post(`${process.env.REACT_APP_AIRDEVE_URL}users/me/`, data,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }})
        .then (() => {axios
          .get(`${process.env.REACT_APP_AIRDEVE_URL}users/me/`,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
          .then((res) => {
            setUserData(res.data);
          })
          .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));

  }

  const updateEmail = () => {
    const data = new FormData();
    data.append("email", email);
    updateMyInfo(uidToken, data);
  };
  /*const updatePassword = () => {
    const data = new FormData();
    data.append("password", password);
    dispatch(updateMyInfo(uidToken, data));
  };*/
  const updateUsername = () => {
    const data = new FormData();
    data.append("username", username);
    updateMyInfo(uidToken, data);
  };
  const updateBio = () => {
    const data = new FormData();
    data.append("bio", bio);
    updateMyInfo(uidToken, data);
  };

 
  const updateRole = () => {

    
   if (userData.role === "ORGANIZER")
    {
        dispatch(changeToUser(uidToken));
        
    }
    else {
        dispatch(changeToOrganizer(uidToken));
    }
    
    
  };

  return ( 
    <div className="myInfo">
     
      <br />
      <h2> Vos Informations </h2>
      <br />

      <lu>
            {/*<div className="form">
            <ul>
                
                <p> Vous êtes un : "{ userData && userData.role}" </p>
                <input
                  type="text"
                  Value={userData && userData.role}
                />
              
                
                <button onClick={()=>{
                            if (window.confirm("Voulez-vous vraiment changer votre rôle")) {
                                updateRole();
                            }}
                        }> Changer de rôle </button>
              
              </ul>
                      </div> */}
          <div className="form">
            <ul>
            
              <p> Votre email : {userData && userData.email} </p>
                <input
                  type="text"
                  placeholder="Email"
                  defaultValue={userData && userData.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
          
              <button onClick={()=>{
                            if (window.confirm("Voulez-vous vraiment changer votre email?")) {
                                updateEmail();
                            }}
                        }> Modifier votre email </button>
            </ul>
          </div>
          <div className="form">
            <ul>
              <p> Votre pseudo est : "{userData &&  userData.username}" </p>
                <input
                  type="text"
                  placeholder="Username"
                  defaultValue={ userData && userData.username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              <button onClick={()=>{
                            if (window.confirm("Voulez-vous vraiment modifier votre pseudo?")) {
                                updateUsername();
                            }}
                        }> Modifier votre pseudo </button>
            </ul>
          </div>
          <div className="form">
          <ul>
              <p> Votre bio </p>
              <textarea
              placeholder="Bio"
              defaultValue={userData && userData.bio}
              onChange={(e) => setBio(e.target.value)}
            />
              <button onClick={()=>{
                            if (window.confirm("Voulez-vous vraiment mettre à jour votre bio?")) {
                                updateBio();
                            }}
                        }> Modifier ma bio </button>
            </ul>
          </div>
      </lu>
    
    </div>
  );
};

export default MyInfo;
