import React from "react";
import { FaFacebook, FaInstagram, FaTiktok, FaLinkedin } from "react-icons/fa";
import { NavLink } from "react-router-dom";


const Footer = () => {
  return (
    <div className="footer">
      <div className="line" />
      <ul class="footerComponents">
        <ul className="more_info">
          <h2> PLUS D'INFORMATIONS</h2>
          <li> 
            <NavLink exact to="Contact" activeClassName="footer-active" style={{ textDecoration: 'none' }}>
              Nous contacter
            </NavLink>
          </li>
          <li> 
            <NavLink exact to="/" activeClassName="footer-active" style={{ textDecoration: 'none' }}>
              Accueil
            </NavLink>
            </li>
          <li> 
            <NavLink exact to="About" activeClassName="footer-active" style={{ textDecoration: 'none' }}>
            À propos
            </NavLink>
          </li>
          <li> 
            <NavLink exact to="EventCreation" activeClassName="footer-active" style={{ textDecoration: 'none' }}>
              Créer un évènement
            </NavLink>
            </li>
        </ul>

        <ul className="legalNotice">
          <h2> Mentions légales</h2>
          <li> 
            <NavLink exact to="LegalNotice" activeClassName="footer-active" style={{ textDecoration: 'none' }}>
              Mentions Légales
            </NavLink>
          </li>
          <li> 
            <NavLink exact to="Cgu" activeClassName="footer-active" style={{ textDecoration: 'none' }}>
            Conditions générales d'utilisation
            </NavLink>
            </li>
          <li> 
            <NavLink exact to="CookiesPolicies" activeClassName="footer-active" style={{ textDecoration: 'none' }}>
              Politiques des cookies
            </NavLink>
            </li>
          <li> 
            <NavLink exact to="PersonnalData" activeClassName="footer-active" style={{ textDecoration: 'none' }}>
              Données personnelles
            </NavLink>
          </li>
        </ul>







        <ul className="social_media">
          <h2> RÉSEAUX SOCIAUX</h2>
          <li> 
          <NavLink
            exact
            to={{ pathname: "https://www.instagram.com/airdeve_fr" }}
            target="_blank" style={{ textDecoration: 'none' }}
          > Instagram
          </NavLink>
            </li>
          <li> 
          <NavLink
            exact
            to={{ pathname: "https://www.facebook.com/airdeve" }}
            target="_blank" style={{ textDecoration: 'none' }}
          > Facebook
          </NavLink>
          </li>
          
          <li>
          <NavLink
            exact
            to={{ pathname: "https://www.linkedin.com/company/airdeve" }}
            target="_blank" style={{ textDecoration: 'none' }}
          > LinkedIn
          </NavLink>
            </li>
          <li> 
          <NavLink
            exact
            to={{ pathname: "https://www.tiktok.com/@airdeve" }}
            target="_blank" style={{ textDecoration: 'none' }}
          > TikTok
          </NavLink>
          </li>
        </ul>
      </ul>

      <div className="lastFooter">
        <div className="footer-img">
          <img src="../img/new_logo.svg" alt="Logo" />
        </div>
        <div className="copyright">
            <h4>Copyright © Airdeve 2022, France </h4>
        </div>
        <div className="social-icons">
          <NavLink
            className="social-icon-link"
            exact
            to={{ pathname: "https://www.facebook.com/airdeve" }}
            target="_blank"
            aria-label="Facebook"
          >
            <FaFacebook />
          </NavLink>
          <NavLink
            className="social-icon-link"
            to= {{ pathname: "https://www.instagram.com/airdeve_fr" }}
            target="_blank"
            aria-label="Instagram"
          >
            <FaInstagram />
          </NavLink>
          <NavLink
            className="social-icon-link"
            to={{ pathname: "https://www.linkedin.com/company/airdeve" }}  
            target="_blank"
            aria-label="LinkedIn"
          >
            <FaLinkedin />
          </NavLink>
          <NavLink
            className="social-icon-link"
            to=  {{ pathname: "https://www.tiktok.com/@airdeve" }}
            target="_blank"
            aria-label="TikTok"
          >
            <FaTiktok />
          </NavLink>
        </div>
      </div>
    
      
    </div>
  );
};

export default Footer;
