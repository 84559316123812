import React, { useEffect, useState } from "react";
import ProfileDetails from '../components/ProfileDetails';
import firebase from "../utils/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../actions/user.actions";



const Profile = () => {

    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userReducer);
    const [uidToken, setUidToken] = useState('');
    
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) { 

        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
            setUidToken(idToken);
            dispatch(getUser(idToken));
          }).catch(function(error) {
            console.log(error);
          });
        }

        })
        
      },[uidToken]);
    

    return (
        <div>
            <ProfileDetails userData = {userData}/>
        </div>
    )
}

export default Profile;