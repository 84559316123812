import React from 'react';
import { useEffect } from 'react';

const LegalNotice = () => {



    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <div className='cgu-form'>
            <h1> MENTIONS LÉGALES</h1>
            <h2>Propriété du Site :</h2>
             <h5>
             Ce Site appartient et est exploité par Airdeve (ci-après la "Société"). La Société est une société par actions simplifiée au capital de 2 000 € , 
             dont le siège social est situé au 1 centre commercial La Tour 93120 LA COURNEUVE, 
             immatriculée au Registre du Commerce et des Sociétés de Bobigny sous le numéro B 912 553 211.
            </h5> 
            <h2>Représentant légal:</h2>
             <h5>
             Le représentant légal de la société est Monsieur Jean-François SIMONNET
            </h5> 

            <h2>Hébergement du Site : </h2>
             <h5>
             La plateforme est hébergée sur Heroku une entreprise du groupe Salesforce dont le siège est : salesforce.com, inc. Salesforce Tower 415 Mission Street, 3rd Floor San Francisco, California 94105. 
             </h5>
             <h5>
             Elle utilise les services tiers que sont AWS d’Amazon dont le siège social est :  Amazon EU S.à r.l., 38 avenue John F. Kennedy, L-1855 Luxembourg, Grand-Duché du Luxembourg pour stocker des images et vidéos.
             </h5>
             <h5>
                Firebase de Google pour l’authentification des utilisateurs dont le siège social est: Gordon House, Barrow Street Dublin 4 Irlande.
                </h5>
                <h5>
                Stripe comme prestataire de paiement dont le siège social est: 3180 18TH STREET CA 94110 SAN FRANCISCO.
            </h5> 
            <h2>Coordonnées de la Société : </h2>
             <h5>
             Airdeve  est une solution éditée par Airdeve  SAS, société au capital de 2 000€.
             </h5>
<br/>
<h5>
Numéro d'immatriculation : B 912 553 211 R.C.S Bobigny
</h5>
<br/>
<h5>
SIRET : 912 553 211 00018
</h5>
<br/>
<h5>
N° TVA: FR30912553211
</h5>
<br/>
<h5>
Adresse: 1 centre commercial La Tour 
</h5>
<br/>
<h5>
93120 LA COURNEUVE
</h5>
<br/>
<h5>
Email: contact@airdeve.com
</h5>
<br/>
<h5>
Représentant Légal : Jean François SIMONNET
</h5> 
            
            
        </div>
    );
};

export default LegalNotice;