import { useDispatch,useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import firebase from "../../utils/firebaseConfig";
import { updateTicket } from "../../actions/tickets.actions";
import axios from "axios";
import { isEmpty } from "../Utils";
import Login from '../Routes/Login';



const TicketCard = ({ticket, eventDetail}) => {

    const [name, setName] = useState(ticket.name);
    const [quantity, setQuantity] = useState(ticket.quantity);
    const [promotionCode, setPromotionCode] = useState();
    const [description, setDescription] = useState(ticket.description);
    const [cost, setCost] = useState(ticket.cost.majorValue);
    const [ordernumber, setOrderNumber] = useState(1);
    const dispatch = useDispatch();
    const [uidToken, setUidToken] = useState();
    const userData = useSelector((state) => state.userReducer);
    const [user, setUser] = useState(false);
    const idEvent = ticket.eventId;
    //const eventUid = eventDetail.uid;
    const ticketId = ticket.id;
    const [loginPopUp, setLoginPopUp] = useState(false);
    const [event, setEvent] = useState();
    const [eventPassword, setEventPassword] = useState();
    const [code, setCode] = useState();
    const [percentOff, setPercentOff] = useState();



    useEffect(() => {
      
      eventDetail &&
      axios
    
        .get(
          `${process.env.REACT_APP_AIRDEVE_URL}events/` + eventDetail.uid
        )
        .then((res) => {
          setEvent(res.data);
        })
        .catch((err) => console.log(err));
    
    }, []);  

    useEffect(() => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUser(true);
          firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            setUidToken(idToken);
          })
          .catch(function (error) {
            console.log(error); });
          
        }
        else{
          setUser(false);
        }
        
      });
  
      },[]);

      


   /* useEffect(() => {
      firebase.auth().onAuthStateChanged((user) => {
        console.log(ticket)
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            setUidToken(idToken);
          })
          .catch(function (error) {
            console.log(error); });
          });
      }, [dispatch]);
*/

      const orderTicket = async() => {

     
        if(event && event.isRestricted === true) {
          await axios
        .post(`${process.env.REACT_APP_AIRDEVE_URL}orders/new/`, 
        {
          "cancelUrl": "https://www.airdeve.com",
          "successUrl": "https://www.airdeve.com",
          "eventId": idEvent,
          "password": eventPassword,
          "ticketTypeId": ticketId,
          "quantity": ordernumber,
          "promotionCode": promotionCode,
          /*"items": [
            {
            "ticketTypeId": ticketId,
            "quantity": ordernumber,
            "promotionCode": promotionCode,

            }
        ]*/
        }, 
        {
          headers: {
              Authorization: `Bearer ${uidToken}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
           }})
          
          .then ((res) => {
            
            if (res.data) {
              window.location.assign(res.data);;
            }
            else {
              alert("Ticket acheté et disponible dans vos commandes");
            }

           })
          .catch((err) => alert("Mot de passe incorrect! Merci de rentrer le bon mot de passe."));

        }

        else {
          await axios
        .post(`${process.env.REACT_APP_AIRDEVE_URL}orders/new/`, 
        {
          "cancelUrl": "https://www.airdeve.com",
          "successUrl": "https://www.airdeve.com",
          "eventId": idEvent,
          "ticketTypeId": ticketId,
          "quantity": ordernumber,
          "promotionCode": promotionCode,
          /*"items": [
            {
            "ticketTypeId": ticketId,
            "quantity": ordernumber,
            }
        ]*/
        }, 
        {
          headers: {
              Authorization: `Bearer ${uidToken}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
           }})
          
          .then ((res) => {
            
            if (res.data) {
              window.location.assign(res.data);
            }
            else {
              alert("Ticket acheté et disponible dans vos commandes");
            }

           })
          .catch((err) => alert(err));
          
        }
        
      };


      const updateTicket = () => { 
        axios
        .patch(`${process.env.REACT_APP_AIRDEVE_URL}ticket-types/` + ticketId + "/", 
        {
          "name": name,
          "quantity": quantity,
          "description": description,
        }, 
        {
          headers: {
              Authorization: `Bearer ${uidToken}`,
              
           }})
          
          .then ((res) => {
            window.location.reload(false);
           })
          .catch((err) => alert(err));
      };


      const createDiscount = async() => { 
        await axios
        .post(`${process.env.REACT_APP_AIRDEVE_URL}discounts/new`, 
        {
          "code": code,
          "ticketTypeId": ticketId,
          "percentOff": percentOff
        }, 
        {
          headers: {
              Authorization: `Bearer ${uidToken}`,
              
           }})
          
          .then ((res) => {
            alert("code promo créé");
            window.location.reload(false);
           })
          .catch((err) => alert(err));
      };

      
    return (
        <div className="buy-ticket">

          {eventDetail && (!isEmpty(eventDetail.organizer) && userData.id === eventDetail.organizer.id)? (
          
            
            
            
            <div className="edit-ticket"> 

              
              <h5> Total de ticket à vendre : {ticket.quantity} </h5>
              <h5> Total de tickets vendus: {ticket.quantitySold} </h5>
              <div className="form">
                <ul>
                  <p> Nom: {ticket.name} </p>
                  <input
                    type="text"
                    defaultValue={ticket.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </ul>
              </div>

              <div className="form">
                <ul>
                  <p> Description du ticket</p>
                  <textarea
                    defaultValue={ticket.description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </ul>
              </div>

              <div className="form">
                <ul>
                  <p> Nombre de ticket : {ticket.quantity}  </p>
                  <input
                    type="number"
                    defaultValue={ticket.quantity}
                    min= '0'
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </ul>
              </div>
              

                <div>
                  <button onClick={()=>{ 
                            if (window.confirm("Voulez-vous vraiment modifier le ticket?")) {
                                updateTicket();
                            }}
                        }> Modifier le ticket </button>
                </div> 

                <div className="createPromotionCode"> 

                <div className="form">
                <ul>
                  <p> Nom du code promo: </p>
                  <input
                    type="text"
                    placeholder="Entrez le nom de votre code promo"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </ul>
              </div>

              <div className="form">
                <ul>
                  <p> Pourcentage du code promo: </p>
                  <input
                    type="number"
                    placeholder="Entrez le pourcentage de réduction"
                    min= '0'
                    max= '100'
                    onChange={(e) => setPercentOff(e.target.value)}
                  />
                </ul>
              </div>
              <div>
                  <button onClick={()=>{ 
                            if (window.confirm("Voulez-vous vraiment créer ce code promo?")) {
                                createDiscount();
                            }}
                        }> Créer un code promo </button>
                </div> 


                </div>

            </div>
          ): (
            <div>
          <div>
            <h5> {ticket.name} </h5>
            <h6> {ticket.description} </h6>
            <h5> Prix: {ticket.cost === "0"? "Gratuit" : ticket.cost.majorValue + "€"} </h5>
            <div className="ticket-line"> </div>

          </div>
          <div className="pre-command-button">
          <input
                    type="number"
                    min= "1"
                    placeholder="nombre de ticket"
                    value={ordernumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />

                  {event && ((event.status === "CANCELED")|| (ticket.quantitySold >= ticket.quantity))? 
                    <button onClick={()=>{
                      //console.log(event);
                      alert("ventes terminées")
                      }}> Fin des ventes </button>:

                    <button onClick={()=>{
                    if(user) {
                      if (event && event.isRestricted === true && eventPassword == null )

                      {
                        alert ("Événement privé ! Veuillez entre le mot de passe pour prendre votre place.")
                      }
                      else{
                        if (window.confirm("Confirmez l'achat de votre billet!")) {
                        orderTicket(); }
                      }

                     
                        
                    } 
                    else {
                        setLoginPopUp(true);
                    }
                     }}> Pré-commander</button>

                   
                  
                             } 
          
                    
          </div>
          <div className="promotionCodeArea"> 
          <input
                    type="text"
                    placeholder="Entrez un code promo si vous en avez"
                    value={promotionCode}
                    onChange={(e) => setPromotionCode(e.target.value)}
                  />
          </div>

          {event && event.isRestricted === true && 
          <div className="passwordArea"> 
          <input
                    type="text"
                    placeholder="Entrez le mot de passe"
                    value={eventPassword}
                    onChange={(e) => setEventPassword(e.target.value)}
                  />
          </div>}
          
          </div>
          )}

{
            loginPopUp && (
              <Login/> )
          }
        </div>
    );
};

export default TicketCard;