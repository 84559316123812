import axios from "axios";

export const GET_USERS = "GET_USERS";



export const getUsers = (idToken) => {


    return (dispatch) => {
        return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}users/` ,{
            headers: {
                Authorization: `Bearer ${idToken}`,
            }})
            .then ((res) => {
                dispatch({type: GET_USERS, payload: res.data})
            })
            .catch((err) => console.log(err));
};
};



