import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
//import axios from "axios";
import firebase from "../../utils/firebaseConfig";
import { isEmpty } from '../Utils';
import { useDispatch } from "react-redux";
import { toggleLike } from '../../actions/postLike.actions';


const LikeButton = ({likes, postId}) => {

    const [liked, setLiked] = useState(false);
    const [likeLength, setLikeLength]= useState(likes.length)
    const userData = useSelector((state) => state.userReducer);
    const [uidToken, setUidToken] = useState('');
    const dispatch = useDispatch();
    

    const like = () => {

        //console.log(uidToken);
        //console.log(postId);
        dispatch(toggleLike(uidToken, postId));
        setLiked(true);
        setLikeLength(likeLength+1);

    };

    const unlike = () => {
        //console.log(uidToken);
        //console.log(postId);
        dispatch(toggleLike(uidToken, postId));
        setLikeLength(likeLength-1);
        setLiked(false);

    };


    useEffect(() => {
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
            
            setUidToken(idToken);

        }).catch(function(error) {
            //console.log(error);
           
        });

        !isEmpty(likes[0]) && likes.map((like) => {
            if (userData.id === like.authorId) setLiked(true);
        })
       //console.log(likes);
       

    }, [likes, userData]);

    return (
        <div className='like-container'>
            {liked === false && 
                <img src= "../img/like.svg" onClick={like}  alt="like"/>
            }
            {liked && 
                <img src= "../img/like-filled.svg" onClick={unlike}  alt="unlike"/>
            }
            
            <span> {likeLength} </span>
        </div>
    );
};

export default LikeButton;