import React, { useEffect, useState } from 'react';
import firebase from "../../utils/firebaseConfig";
import { useSelector } from 'react-redux';
import axios from "axios";
import { timestampParser, isEmpty } from '../Utils';
import EditDeleteComment from './EditDeleteComment';


const Comments = (props) => {
    let eventDetail = props.event;
    const [comments, setComments] = useState([]);
    const [text, setText] = useState("");
    const eventId = eventDetail.id;
    const [uidToken, setUidToken] = useState('');
    const userData = useSelector((state) => state.userReducer);
    const usersData = useSelector((state) => state.usersReducer); 
    const [loginPopUp, setLoginPopUp] = useState(false);

    const handleComment =(e) => {
        e.preventDefault();
        if(text){
        const params = JSON.stringify({
            "payload": text,
        });
        axios
        .post(`${process.env.REACT_APP_AIRDEVE_URL}events/` + eventId + "/comments" , params, {
        headers: {
            Authorization: `Bearer ${uidToken}`,
            'Content-Type' : 'application/json',
         }})
        .then ((res) => {
        setText('');
        //window.location.reload(false);
         })
        .catch((err) => console.log(err));

        }

    };

    useEffect(()=> {
       if (uidToken) {
        axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId +"/comments/" ,{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                setComments(res.data);
            })
            .catch((err) => console.log(err));

       }

            
    }, [uidToken,comments,text])




    useEffect(()=> {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) { 
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){
            setUidToken(idToken);
         /*   axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId +"/comments/" ,{
            headers: {
                Authorization: `Bearer ${idToken}`,
            }})
            .then ((res) => {
                setComments(res.data);
            })
            .catch((err) => console.log(err));*/
              
        });

    } else {
        setLoginPopUp(true);
      }
    });
    }, [])

    return (
        <div className='comments-container'>
            {
                loginPopUp?(
                    <div> 
          <br/>
          <h3> Vous n'êtes pas connecté! Merci de vous connecter pour voir et laisser des commentaires. </h3>
          </div>

                ):(

            <div> 
            {userData.id && (
                <form action="" onSubmit={handleComment} className="comment-form">
                    <input type="text" name="text" onChange={(e)=> setText(e.target.value)} value={text} placeholder="commenter"/>
                    <br/>
                    <input type="submit" value ="Envoyer" />

                </form>
            )}
            
            {comments.slice(0).reverse().map((comment)=> {
                return (
                    <div className={comments.authorId === userData.id 
                    ? "comment-container client": "comment-container"}
                    key={comments.id}> 
                    <div className='left-part'> 
                        <img src={!isEmpty(usersData[0]) && usersData.map((user)=> {
                            if(user.id === comment.authorId) 
                            return user.profileImgUrl;
                            else return null;
                        }).join("")}
                        alt= "commenter-pic" />
                    </div>
                    <div className='right-part'> 
                        <div className='comment-header'>
                            <div className='pseudo'> 
                            <h3> {comment.author.username} </h3>
                            </div>
                            <span> {timestampParser(comment.createdAt)} </span>
                            
                        </div>
                        <p> {comment.payload}</p>
                        <EditDeleteComment comment={comment}/>

                    </div>
                    </div>
                )
            })}
            

            </div>

                    
)
}
            
        </div>
    );
};

export default Comments;