import axios from "axios";

export const GET_EVENT_TICKETS = "GET_EVENT_TICKETS";

export const getEventTickets = (eventId, uidToken) => {
    return (dispatch) => {
        return axios 
        .get(`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId +"/tickets",  {
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }})
        .then ((res) => {
            dispatch({type: GET_EVENT_TICKETS, payload: res.data})
        })
        .catch((err) => console.log(err));
    }
}

export const updateTicket = (uidToken,eventId, data, ticketId) => {
    return (dispatch) => {
        return axios 
        .post(`${process.env.REACT_APP_AIRDEVE_URL}ticket-types/`+ ticketId +"/",  {
            headers: {
                Authorization: `Bearer ${uidToken}`,
        }})
        .then ((res) => {
            return axios
            .get (`${process.env.REACT_APP_AIRDEVE_URL}events/`+ eventId +"/tickets",{
            headers: {
                Authorization: `Bearer ${uidToken}`,
            }})
            .then ((res) => {
                dispatch({type: GET_EVENT_TICKETS, payload: res.data})
            })
        })
        .catch((err) => console.log(err));
    }
}