import firebase from "../utils/firebaseConfig";
import React, { useState, useEffect } from "react";
import { useSelector} from "react-redux";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import { createEvent } from "../actions/allEvents.actions";
//import { dateToISOStringParser } from "./Utils";
import Login from './Routes/Login';




const CreateEvent = () => {
  const [eventPicture, setEventPicture] = useState("./img/default_PP.png");
  const [nameEvent, setNameEvent] = useState("");
  const [eventCategory, setEventCategory] = useState("music");
  const [amountNeeded, setAmountNeeded] = useState("");
  const [taxRate, setTaxRate] = useState(0);
  const [salesEnd, setSalesEnd] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [currency, setCurrency] = useState("EUR");
  const [capacity, setCapacity] = useState("");
  const [dateApproximation, setDateApproximation] = useState("");
  const [ticketName, setTicketName] = useState("");
  const [ticketQuantity, setTicketQuantity] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [ticketCost, setTicketCost] = useState(10);
  const [ticketTotal, setTicketTotal] = useState("");
  const [absorbFees, setAbsorbFees] = useState(false);
  const [free, setFree] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);
  const [password, setPassword] = useState("");

  const dict = [
   { value:  "music",
     label:  "Musique",
  },
    {value:  "sport",
    label:  "Evènement Sportif",
  },
    {value:  "hobbies",
    label:  "Loisir",
  },
    {value:  "art",
    label:  "Exposition et vernissage"
  },
    {value:  "spectacles",
    label:  "Théâtre et spectacle",
  },
    {value:  "charity",
    label:  "Evènement associatif",
  },
    {value:  "conferences",
    label:  "Conférences et Ateliers",
  }
  ]


  
  
  const [file, setFile]= useState();
  const [uidToken, setUidToken]= useState();
  const [loginPopUp, setLoginPopUp] = useState(false);
  //const dispatch= useDispatch();
  const userData = useSelector((state) => state.userReducer);

  const acceptAbsorbFees = ()=> {
    setAbsorbFees(!absorbFees);

  }
  const privatiseEvent = ()=> {
    setIsRestricted(!isRestricted);

  }

  const simulate = (e) =>{
    setTicketCost(e.target.value);

  }

  const changeAccess = ()=> {
    setFree(!free);

  }

  const handlePicture = (e) => {
    setEventPicture(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
 };
  useEffect(()=> {
    axios 
      .get(`${process.env.REACT_APP_AIRDEVE_URL}ticket-types/simulate?cost=`+ticketCost+"&absorbFee="+absorbFees+"&taxRate="+taxRate+"&currency="+currency,
      {
        headers: {
            Authorization: `Bearer ${uidToken}`,
        }}
      )
        
        .then ((res) => {
          setTicketTotal(res.data.cost.majorValue)
         })
        .catch((err) => {
          console.log(err);

        }
          );
}, [ticketCost])

  useEffect(() => {

    
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.auth().currentUser.getIdToken(true).then(function(idToken){

          setUidToken(idToken); 
           }).catch(function(error) {
              console.log(error);
          }); 

      }
      else {
        setLoginPopUp(true);
      }
    });

    },[uidToken]);
  

  
  
  // Evt object creation

  const createEvt = async() => {
   
    
    if (nameEvent && eventPicture && eventCategory && amountNeeded && capacity && 
      taxRate && salesEnd && description && location && currency && dateApproximation
      && ticketName && ticketQuantity && ticketDescription && ticketCost && file && file.size < 1e6) {
      const data= new FormData();
      const isoDate = salesEnd.concat("T00:00:00.000Z");
      //console.log(isoDate);
    
  
      data.append('coverImg', file);
      data.append('name', nameEvent);
      data.append('category', eventCategory);
      data.append('goal', amountNeeded);
      data.append('taxRate', taxRate);
      data.append('salesEnd', isoDate);
      data.append('description', description);
      data.append('location', location);
      data.append('currency', currency);
      data.append('capacity', capacity);
      data.append('password', password);
      data.append('isRestricted', isRestricted);
      data.append('dateApproximation', dateApproximation);
      data.append('ticketType[name]', ticketName);
      data.append('ticketType[quantity]', ticketQuantity);
      data.append('ticketType[description]', ticketDescription);
      data.append('ticketType[cost]', ticketCost);
      data.append('ticketType[absorbFee]', absorbFees);
      data.append('ticketType[free]', free);
      

     // console.log(data);

      await axios 
      .post(`${process.env.REACT_APP_AIRDEVE_URL}events/new`, 
      data, 
      {
        headers: {
            Authorization: `Bearer ${uidToken}`,
         }})
        
        .then ((res) => {
          alert("Votre évènement a bien été créé")
         })
        .catch((err) => {
          alert(err);
        alert("évènement non créé")

        }
          );
        
        setNameEvent("");
        setEventCategory("Musique");
        setAmountNeeded("");
        setTaxRate("");
        setSalesEnd("");
        setDescription("");
        setLocation("");
        setCurrency("EUR");
        setCapacity("");
        setDateApproximation("");
        setTicketName("");
        setTicketQuantity("");
        setTicketDescription("");
        setTicketCost("");
        setTicketName("");
        setEventPicture("./img/default_PP.png");
        setAbsorbFees(false);
        setPassword("");

    } else {
      alert("veuillez remplir toutes les informations pour créer votre évènement et ajouter une affiche(taille max de 1Mo)")
    }

  };

  return (
    
    <div className="createEvent">
      <br />
      <br />
      <h1> CRÉEZ VOTRE ÉVÉNEMENT</h1>
      <br />
      {
        loginPopUp? (  
        <Login/> ) : ( 

      <div>

      

       
      {(userData.role === "ORGANIZER") ? (
      <div className="eventInfo">
      <div> 
          <ul className="block">
            <ul className="columnLeft">
              <li>
                <div className="form">
                  <img src={eventPicture} alt="Affiche" />
                  <label htmlFor='file'> Choisir une affiche</label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    class="btn"
                    accept='.jpg, .jpeg, .png' 
                    onChange={(e)=> handlePicture(e)}
                  />
               
                </div>
                <br />
              </li>

              <li>
                <div className="eventDescription">
                <p> Faites une description de votre évènement</p>

                  <CKEditor
                  editor= {ClassicEditor}
                  data = {description}
                  onChange ={(e, editor) => {
                    const data = editor.getData()
                    setDescription(data)
                  }}
                  />
                </div>
              </li>
            </ul>
            <ul className="columnRight">
              <li>
              
                <div className="form">
                <h5> Donnez un nom à votre évènement</h5>
                  <input
                    type="text"
                    placeholder="Nom de l'évènement"
                    value={nameEvent}
                    onChange={(e) => setNameEvent(e.target.value)}
                  />
                </div>
                <br />
              </li>
                <li>
                <div className="form">
                <h5> Choisir la catégorie de votre évènement</h5>

                  <select value={eventCategory}
                    onChange={(e) => setEventCategory(e.target.value)}>
                    {dict.map((option) => (
                    <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                  </div>
                </li>
              <li>
                <div className="form">
                <h5> Montant demandé pour la validation de votre campagne</h5>

                <input
                    type="number"
                    placeholder="Montant demandé"
                    min="0"
                    value={amountNeeded}
                    onChange={(e) => setAmountNeeded(e.target.value)}
                  />
                </div>
              </li>
              <li>
                <div className="form">
                <h5> Si vous payez la TVA, indiquez le montant en pourcentage de la taxe en % </h5>
                  <input
                    type="number"
                    placeholder="Taxe applicable"
                    min="0"
                    value={taxRate}
                    onChange={(e) => setTaxRate(e.target.value)}
                  />
                </div>
              </li>
              <li>
                <div className="form">
                <h5> Donnez la date de fin de votre campagne de financement. Elle doit être à moins de 6 mois du lancement de la campagne.</h5>
                  <input
                    type="date"
                    placeholder="Date de l'évènement"
                    value={salesEnd}
                    onChange={(e) => setSalesEnd(e.target.value)}
                  />
                </div>
              </li>
              <li>
                <div className="form">
                <h5> Précisez un lieu, une ville, une région ou une adresse pour la tenue de votre évènement</h5>
                  <input
                    type="text"
                    placeholder="Lieu de tenue de l'évènement"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
              </li>

              <li>
                <div className="form">
                <h5> Donnez le nombre maximum de place disponible</h5>
                  <input
                    type="number"
                    placeholder="Nombre maximum de particpants"
                    value={capacity}
                    onChange={(e) => setCapacity(e.target.value)}
                  />
                </div>
              </li>
              <li>
                <div className="form">
                <h5> Donnez une date ou une période de déroulement de votre évènement. Ex: 22 Juin 2022, Fin Septembre 2022</h5>
                  <input
                    type="text"
                    placeholder="Période où aura lieu votre évènement"
                    value={dateApproximation}
                    onChange={(e) => setDateApproximation(e.target.value)}
                  />
                </div>
              </li>
              
            </ul>
          </ul>
        
        </div>
        <div className="ticket">
            <br/>
            <h2> CREATION DE TICKET</h2>

            <ul className="ticketGroup">
                <li className= "ticketLeft">
                    <div className="form">
                        <textarea  type="text" placeholder= "Description du ticket" value={ticketDescription} onChange={(e) => setTicketDescription(e.target.value)}
                        />
                    </div>
                </li>

                <ul className = "ticketRight">
                    <li>
                        <div className="form">
                        <input  type="text" placeholder= "Nom du ticket" value={ticketName} onChange={(e) => setTicketName(e.target.value)}
                        />
                        </div>
                     </li>

                    <li>
                        <div className="form">
                        <input  type="number" min= "1" placeholder= "Nombre de tickets à vendre" value={ticketQuantity} onChange={(e) => setTicketQuantity(e.target.value)}
                        />
                    </div>
                    </li>
                   
                     <li>
                       { (free === false) && <div className="form">
                          
                        <input  type="number" min="0" id="price " placeholder= "Prix d'un ticket" value={ticketCost} onChange={(e) => {simulate(e)}}
                        />
                        <h5> Le participant paiera: {ticketTotal} €</h5>
                      
                        </div>
                        }
                        <div className="checkboxForm">
                        <input
                          type="checkbox" 
                          onChange={changeAccess}>
                        </input>
                         Le ticket est gratuit
                        </div>
                        { (free === false) &&
                          <div className="checkboxForm">
                          <input
                            type="checkbox" 
                            onChange={acceptAbsorbFees}>
                          </input>
                           Prise en charge des frais (Les frais seront déduit du montant payé par le participant)
                          </div>
                        }
                        <div className="checkboxForm">
                        <input
                          type="checkbox" 
                          onChange={privatiseEvent}>
                        </input>
                         Rendre l'événement privé
                        </div>
                      { (isRestricted === true) &&   
                        <div className="form">
                        <input  type="text" placeholder= "Mettre un mot de passe" value={password} onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>}
                    
                        
                        
                    </li>

                </ul>

            </ul>
        </div>
        <div>
            <button onClick={createEvt}> Créer l'évènement </button>
        </div> 

        </div>
        ): ( 

          window.location.assign("https://0paj666dl2c.typeform.com/to/o6EAxQW4")

          )}
      

      </div> )
      }


    </div>
    
  ); 
  
};

export default CreateEvent;
