import React, { useState, useEffect, } from "react";
import firebase from "../../utils/firebaseConfig";
import { auth } from "../../utils/firebaseConfig";
import {signInWithEmailAndPassword}from "firebase/auth";
import { Redirect } from "react-router-dom";

import { useNavigate } from 'react-router-dom';
import axios from "axios";


const Login = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [password, setPassword] = useState("");
  const [userPic, setUserPic] = useState("../img/BlankImage.jpeg");
  const [file, setFile] = useState(null);
  const [loginPopUp, setLoginPopUp] = useState(true);
  const [connectDisplay, setConnectDisplay] = useState(true);
  const [connectEmail, setConnectEmail] = useState("");
  const [connectPassword, setConnectPassword] = useState("");
  const [validateCGU, setValidateCGU] = useState(false);


  const closePopup = () => {
    setLoginPopUp(false);
    window.location.href = '/';
 
  };

  const handlePic = (e) => {
    setUserPic(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const connect = () => {
    setConnectDisplay(true);
  };

  const createDisplay = () => {
    setConnectDisplay(false);
  };

  

  const createUserOnDb = async(userEmail) => {

    const data = new FormData();
    if (file === null) {
      data.append("email", userEmail);
      data.append("username", pseudo);
      data.append("name", name);

    }
    else {
      data.append("profileImg", file);
      data.append("email", userEmail);
      data.append("username", pseudo);
      data.append("name", name);

    }
    
    //console.log(data)
     axios
          .post(`${process.env.REACT_APP_AIRDEVE_URL}users/new`, data)
    
          .then((res) => {
            alert("Compte créé");
            //console.log(res);
            window.location.reload(false);
          })
          .catch((err) => {
            auth.currentUser.delete();
            alert("Format de mail ou de mot de passe incorrect...");
            //window.location.reload();
          });

  };

  const connectUser = async()=> {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        connectEmail,
        connectPassword
      );
      if(user) {
        window.location.reload(false);
      }
    } catch (error) {
      alert("Mot de passe ou mail incorrect...");
    }

  };

  const passwordForgotten = () => {
    if (connectEmail) {
    try  {
      auth.sendPasswordResetEmail(connectEmail)
      .then ( alert("Un mail de réinitialisation de mot de passe vous a été envoyé (Si aucun mail reçu, vérifiez vos spams) "))
      .catch((error) => alert(error.message))
    } catch (error) {
      alert(error.message);
    }
    }
  
    else { 
      alert("Entrer votre adresse email")

    }
  };
  // && file && file.size< 1e6

  const createAccount = () => {

    if(validateCGU && email && password && pseudo ) {
    
        auth.createUserWithEmailAndPassword(
            email,
            password
          )
       .then ( async(userAuth) =>  createUserOnDb(userAuth.user.email))
           
       .catch (error => {
        if (error.code === "auth/email-already-in-use" ) 
        {alert("Email déjà existant ! Veuillez vous connecter ou récupérer votre mot de passe en cliquant sur 'Mot de passe oublié?' ")}
        else {
          alert(error.code)
        }
      }) 

    }
  

    else {
      alert("Accepter les Conditions Générales d'Utilisations,remplissez tout le formulaire")

    }
   
  }; 

  const acceptCGU = ()=> {
    setValidateCGU(!validateCGU);

  }



  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLoginPopUp(false);
      }
      
    });

    },[]);


  return (
    <div className="login-page">
      {loginPopUp && (
        <div className="login-popup">
          <div className="modal">
            <img src="../img/new_logo.svg" alt="logo" />
            <h3> Connexion</h3>

            <span className="cross" onClick={closePopup}>
              {" "}
              &#10005;{" "}
            </span>

            {connectDisplay ? (
              <div className=" connectModal">
                <form>
                <input
                    type="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setConnectEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    required
                    onChange={(e) => setConnectPassword(e.target.value)}
                  />
                </form>
                <div className="connectBtn">
                  <button onClick={connectUser}> Se connecter </button>
                </div>
                <div className="createAccountBtn">
                  <button onClick={createDisplay}> Créer un compte </button>
                </div>
                <div className="passwordForgottenBtn">
                  <button onClick={passwordForgotten}> Mot de passe oublié?</button>
                </div>
              </div>

            ) : (
              <div>
                {/*
                  <div className="user-pic">
                  <img src={userPic} alt="PP" />
                  <label htmlFor="file"> Choisir une Photo de profil</label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    class="btn"
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => handlePic(e)}
                  />
                </div>

            */}
                
                <form>
                  <input
                    type="text"
                    placeholder="Prénom et nom"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Pseudo"
                    required
                    onChange={(e) => setPseudo(e.target.value)}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Mot de passe(plus de 6 caractères)"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </form>
                <div className="createAccountBtn">
                  <button onClick={createAccount}> Créer un compte </button>
                </div>
                <div className="connectBtn">
                  <button onClick={connect}> Se connecter </button>
                </div>
                
                <div class ="acceptCGU"> 
                <input
                 type="checkbox" 
                 onChange={acceptCGU}>
                </input>
                J'accepte les  
                <a href="Cgu" target="_blank">
                Conditions générales d'utilisation
                </a>

                </div>
                
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
