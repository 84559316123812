import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import './styles/index.scss'
import rootReducer from './reducers';

//pour le dev 
/* const store = createStore (
  rootReducer , composeWithDevTools( applyMiddleware(thunk, logger))
) */

const store = createStore (
  rootReducer , applyMiddleware(thunk)
)

ReactDOM.render(
  <Provider store  = {store}>
    <React.StrictMode>
        <App />
    </React.StrictMode>,
  </Provider>,

  document.getElementById('root')
);

