import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import About from '../../pages/About';
import EventCreation from '../../pages/EventCreation';
import Home from '../../pages/Home';
import Profile from '../../pages/Profile';
import NotFound from '../../pages/NotFound';
import Navbar from '../Navbar';
import Event from '../../pages/Event';
import Footer from '../Footer';
import LoginPage from '../../pages/LoginPage';
import OtherProfil from '../../pages/OtherProfil';
import Contact from '../../pages/Contact';
import Cgu from '../../pages/Cgu';
import LegalNotice from '../../pages/LegalNotice';
import CookiesPolicies from '../../pages/CookiesPolicies';
import PersonnalData from '../../pages/PersonnalData';
import MyTickets from '../../pages/MyTickets';
import CheckTicket from '../../pages/CheckTicket';


const index = () => {
    return (
        <Router>
            <Navbar/>
            <br/>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/About" exact component={About}/>
                <Route path="/CheckTicket" exact component={CheckTicket}/>
                <Route path="/EventCreation" exact component={EventCreation}/>
                <Route path="/Profile" exact component={Profile}/>
                <Route path="/events/:eventId" exact component={Event}/>
                <Route path="/orders/:orderId" exact component={MyTickets}/>
                <Route path="/OrganizerProfil" exact component={OtherProfil}/>
                <Route path="/LoginPage" exact component={LoginPage}/>
                <Route path="/Contact" exact component={Contact}/>
                <Route path="/Cgu" exact component={Cgu}/>
                <Route path="/LegalNotice" exact component={LegalNotice}/>
                <Route path="/CookiesPolicies" exact component={CookiesPolicies}/>
                <Route path="/PersonnalData" exact component={PersonnalData}/>
                <Route component={NotFound} />
                <Redirect to="/" />

            </Switch>
            <br/>
            <Footer/>

        </Router>
        
    )
}

export default index;